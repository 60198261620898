import React, { useState, useEffect } from "react";
import st from "../../../style.module.scss";
import cx from "./Forgotpassword.module.scss";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { NavLink, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Col, Row, Form } from "react-bootstrap";
import { MdArrowBackIos } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg-img-new.svg";
import logo from "../../../images/logo.png";

export default function Forgotpassword() {

  const navigate = useHistory();

  //----validation----//
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //---react-toastify-----//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);
  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const [disabledBtn, setDisabledBtn] = useState(false);

  // FORGOT PASSWORD API 
  function forgotPassword(postdata: any) {
    setDisabledBtn(true);
    let formdata = new FormData();
    formdata.append('email', postdata.email)
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/forgetpassword`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response?.data?.statusCode == 200) {
          setTimenotify(response?.data?.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/student/otp-verification',
              state: { detail: response?.data?.data?.userId, email: postdata?.email, component: "" }
            });
          }, 2000);
        }
        else {
          setDisabledBtn(false);
          setTimenotify(response?.data?.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        setDisabledBtn(false);
        notify(error?.response?.data?.message);
        setTimenotify(error?.response?.data?.msg);
      });
  }

  return (
    <>
      <ToastContainer limit={1} />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
              <Row className={`${cx.titleBar}`}>
                <Col>
                  <NavLink to="/student/login" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col>
                </Col>
                <Col className="text-end">
                </Col>
              </Row>
            </div>
            <img alt="logo" src={bg} className={`${cx.bgShape}`} />
            <Col className="text-center"><img alt="logo" src={logo} className={`${cx.logo}`} />
            </Col>
          </div>

          <div className={`${cx.contentBody} mt-3`}>
            <Col lg={4} className={'m-auto'}>
              <h5 className={`${st.themeColor}`}>Forgot Password</h5>
              <p className={`${st.fontColor}`}>Enter the email to recover the password</p>
            </Col>

            <Col lg={4} className={'m-auto'}>
              <Form onSubmit={handleSubmit(forgotPassword)} className={`${st.formFull}`} >
                <Form.Group className={`${st.formField}`} controlId="">
                  <Form.Control type="email" placeholder="Email"
                    {...register("email", {
                      required: true,
                      pattern:
                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    })}
                  />
                  {errors?.email?.type === "pattern" && (
                    <p style={{ color: "red" }}>Invalid email address</p>
                  )}
                  {errors?.email?.type === "required" && (
                    <p style={{ color: "red" }}>
                      This field is required
                    </p>
                  )}
                </Form.Group>

                <Form.Group className={`${st.formField}`}>
                  <Col lg={12}>
                    {disabledBtn ? <button disabled type="submit" className={`btn ${st.actionBtn}`}>
                      Send OTP
                    </button> : <button type="submit" className={`btn ${st.actionBtn}`}>
                      Send OTP
                    </button>}
                  </Col>
                </Form.Group>

              </Form>
            </Col>
          </div>
        </div>
      </section>
    </>
  );
}