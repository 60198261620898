import React, { useEffect, useState } from "react";
import styles from "./CustomDatePicker.module.css";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import {
    addDays,
    addMonths,
    differenceInMonths,
    format,
    isSameDay,
    lastDayOfMonth,
    startOfMonth,
} from "date-fns";
const CustomDatePicker = ({
    endDate,
    selectDate,
    getSelectedDay,
    color,
    labelFormat,
}: any) => {

    const [selectedDate, setSelectedDate] = useState(new Date(Date.now()));
    // const [selectedDate, setSelectedDate] = useState(new Date(Date.now() + (3600 * 1000 * 24)));
    const startDate = new Date(Date.now())
    console.log(startDate, "9824892iurwrwoirwoirw")
    // const startDate = new Date(Date.now() + (3600 * 1000 * 24))
    const oneDate = startDate.setDate(startDate.getDate());
    const lastDate: Date = addDays(oneDate, endDate || 180);
    const primaryColor = color || "rgb(54, 105, 238)";
    const selectedStyle = {
        background: "#375DBC",
        color: "#fff",
    };
    const buttonColor = { background: primaryColor };
    const labelColor = { color: primaryColor };

    const getStyles = (startDate: any) => {
        if (isSameDay(startDate, selectedDate)) {
            return selectedStyle;
        }
        return null;
    };

    const getId = (day: any) => {
        if (isSameDay(day, selectedDate)) {
            return "selected";
        } else {
            return "";
        }
    };

    function renderDays() {
        const dayFormat = "E";
        const dateFormat = "d";
        const months = [];
        let days = [];
        for (let i = 0; i <= differenceInMonths(lastDate, startDate); i++) {
            let start, end;
            const month = startOfMonth(addMonths(startDate, i));
            start = i === 0 ? Number(format(startDate, dateFormat)) - 1 : 0;
            end =
                i === differenceInMonths(lastDate, startDate)
                    ? Number(format(lastDate, "d"))
                    : Number(format(lastDayOfMonth(month), "d"));
            for (let j = start; j < end; j++) {
                days.push(
                    <div
                        id={`${getId(addDays(startDate, j))}`}
                        className={styles.dateDayItem}
                        key={j}
                        onClick={() => onDateClick(addDays(month, j))}
                    >
                        <div className={styles.dayLabel}>
                            {format(addDays(month, j), dayFormat)}
                        </div>
                        <div className={styles.dateLabel} style={
                            getStyles(addDays(month, j)!)!}>
                            {format(addDays(month, j), dateFormat)}
                        </div>
                    </div>
                );
            }
            months.push(
                <>
                    <div className={styles.monthContainer} key={`${month}`}>
                        <span className={styles.monthYearLabel} style={labelColor}>
                            {format(month, labelFormat || "MMMM yyyy")}
                        </span>
                        <div
                            className={styles.daysContainer}
                            style={{}}
                        >
                            {days}
                        </div>
                    </div>
                </>
            );
            days = [];
        }
        return (
            <div id={"container"} className={styles.dateListScrollable}>
                {months}
            </div>
        );
    }

    const onDateClick = (day: any) => {
        setSelectedDate(day);
        if (getSelectedDay) {
            getSelectedDay(day);
        }
    };

    useEffect(() => {
        if (getSelectedDay) {
            if (selectDate) {
                getSelectedDay(selectDate);
            } else {
                getSelectedDay(startDate);
            }
        }
    }, []);

    useEffect(() => {
        if (selectDate) {
            if (!isSameDay(selectedDate, selectDate)) {
                setSelectedDate(selectDate);
                setTimeout(() => {
                    let view = document.getElementById("selected");
                    if (view) {
                        view.scrollIntoView({
                            behavior: "smooth",
                            inline: "center",
                            block: "nearest",
                        });
                    }
                }, 20);
            }
        }
    }, [selectDate]);

    const nextWeek = () => {
        const e = document.getElementById("container")!;
        const width: any = e ? e.getBoundingClientRect().width : null;
        e.scrollLeft += width - 60;
    };

    const prevWeek = () => {
        const e = document.getElementById("container")!;
        const width: any = e ? e.getBoundingClientRect().width : null;
        e.scrollLeft -= width - 60;
    };

    return (
        <div className={styles.container}>
            <div className={styles.buttonWrapper}>
                <button
                    className={styles.button}
                    style={buttonColor}
                    onClick={prevWeek}
                >
                    <MdKeyboardArrowLeft />
                </button>
            </div>
            {renderDays()}
            <div className={styles.buttonWrapper}>
                <button
                    className={styles.button}
                    style={buttonColor}
                    onClick={nextWeek}
                >
                    <MdKeyboardArrowRight />
                </button>
            </div>
        </div>
    );
};

export default CustomDatePicker;