import React, { useEffect } from "react";

const useKeyPress = (targetKey: any) => {
    const [keyPressed, setKeyPressed] = React.useState(false);

    const downHandler = (e: any) => {
        if (e.key === targetKey && e.shiftKey) {
            return;
        } else {
            if (e.key === targetKey) setKeyPressed(true);
        }
    };

    const upHandler = ({ key }: any) => {
        if (key === targetKey) setKeyPressed(false);
    };
    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);
    }, []);
    return keyPressed;
};

export default useKeyPress;