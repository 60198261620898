import React, { useState, useEffect } from "react";
import axios from "axios";
import st from "../../../style.module.scss";
import cx from "./Home.module.scss";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { AiFillEdit, AiFillMessage } from "react-icons/ai";
import { FiDownload } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Row, Form, Button } from "react-bootstrap";
import { HiDocumentCheck } from "react-icons/hi2";
import { MdNotifications, MdOutlineClose } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../../../components/Teacher/Footer/Footer";
import Header from "../../../components/Header/Header";
import ReactLoading from "react-loading";
import mtimezone from "moment-timezone";
import { TiTick } from "react-icons/ti";
import { useForm } from 'react-hook-form';
import { IoLogoWhatsapp } from "react-icons/io";
import { useSelector } from "react-redux";
import { query, collection, orderBy, limit, onSnapshot } from "@firebase/firestore";
import { Unsubscribe } from "@reduxjs/toolkit";
import { db } from "../../../firebase";
import StudentDetails from "../../../components/Modal/Teacher/StudentDetails";
import TeacherRating from "../../../components/Modal/Teacher/TeacherRating";
import TeacherDemoDetails from "../../../components/Modal/Teacher/TeacherDemoDetails";
import AssignByAdmin from "../../../components/Modal/Teacher/AssignByAdmin";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import SwiperCore from 'swiper';
import { FaWallet } from "react-icons/fa";
import Bottombar from "../../../components/Teacher/Bottombar/Bottombar";
import ProfileAvatar from "../../../Custom_Components/ProfileAvatar/ProfileAvatar";
SwiperCore.use([FreeMode, Navigation, Thumbs]);

export default function Teacherhome() {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const [visibleSlidesIndexes, setvisibleSlidesIndexes] = useState<any>();

  useEffect(() => {
    handleSlideChange({ realIndex: location?.state?.index ?? 0 });
  }, [])

  const handleSlideChange = (e: any) => {
    const activeIndex = e?.realIndex;
    switch (activeIndex) {
      case 0:
        upcomingClasses();
        break;
      case 1:
        getdemoClasses();
        break;
      case 2:
        pastClasses();
        break;
      default:
        break;
    }
  };

  const [showStudentDetails, setStudentDetailsShow] = useState(false);
  const handleStudentDetailsClose = () => setStudentDetailsShow(false);
  const handleStudentDetailsShow = () => setStudentDetailsShow(true);

  const [showDemoStudentDetails, setDemoStudentDetailsShow] = useState(false);
  const handleDemoStudentDetailsClose = () => setDemoStudentDetailsShow(false);
  const handleDemoStudentDetailsShow = () => setDemoStudentDetailsShow(true);

  const [showAssignStudentDetails, setAssignStudentDetailsShow] = useState(false);
  const handleAssignStudentDetailsClose = () => setAssignStudentDetailsShow(false);
  const handleAssignStudentDetailsShow = () => setAssignStudentDetailsShow(true);

  const [showTeacherRating, setTeacherRatingShow] = useState(false);
  const handleTeacherRatingClose = () => setTeacherRatingShow(false);
  const handleTeacherRatingShow = () => setTeacherRatingShow(true);

  const homeNotify = useSelector((state: any) => state?.notifyCount);
  const classCount = useSelector((state: any) => state?.classCount);
  let localData = JSON.parse(localStorage.getItem("userData")!);

  const location: any = useLocation();
  const navigate = useHistory();

  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);

  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const handleShow3 = () => setShow3(true);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);
  const [data, setData] = useState<any>([]);
  const [pastData, setPastData] = useState<any>([]);
  const [assign_by_admin, setAssign_by_admin] = useState<any>([]);
  const [zoom_link, setZoom_link] = useState("");
  const [class_topic, setClass_topic] = useState("");
  const [studentapplyfordemo_id, setStudentapplyfordemo_id] = useState("");
  const [contactSupports, setContactSupports] = useState<any>({ enroll_id: "", student_id: "", message: "", });
  const [student_id, setStudent_id] = useState<any>('');
  const [enroll_id, setEnroll_id] = useState<any>('');
  const [student_enroll_schedule_id, setStudent_enroll_schedule_id] = useState<any>('');
  const [classLinkEditS, setClassLinkEditS] = useState(true);
  const [user_id, setUserId] = useState('');
  const [notificatCount, setNotificationCount] = useState("");
  const [start_time, setStart_time] = useState<any>("");
  const [demoStart_Time, setDemoStart_time] = useState('');
  const [loaderStatus, setloaderStatus] = useState(<ReactLoading type="cylon" color="blue" />);
  const [feedback, setFeedback] = useState([]);
  const [upcomingData, setUpcomingData] = useState<any>([]);
  const [assignByAdmin, setAssignByAdmin] = useState<any>([]);
  const [demoDetails, setDemoDetails] = useState([]);
  const [teacher_assignment_pdf, setteacher_assignment_pdf] = useState([]);
  const [disabledBtn, setDisabledBtn] = useState(false);
  //----validation----//
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // )------------------------------GET DEMO CLASSES API (APPLIED/ ASSIGN)------------------------------ //
  function getdemoClasses() {
    let formdata = new FormData();
    formdata.append("userId", localData?.id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/getteacherAssign`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        setNotificationCount(response.data.count)
        if (
          response.data.data.length === 0 &&
          response.data.assign_by_admin.length === 0
        ) {
          setloaderStatus(<div>Class Not Found ...</div>);
        }
        setData(response.data.data);
        response?.data.assign_by_admin?.forEach((item: any) => {
          let splitDate = item?.select_date.split("T")
          let localTime = new Date(`${splitDate[0]} ${item?.start_time} UTC`).toLocaleString("en-US", { timeZone: localData?.timezone });
          let splitTIme = localTime.split(",");
          setDemoStart_time(splitTIme[1]);
          setUserId(item?.user_id);
          setStudentapplyfordemo_id(item?.studentapplyfordemo_id);
        })
        setAssign_by_admin(response.data.assign_by_admin);
      })
      .catch(function (error) {
        console.log(error)
      });
  }

  // )------------------------------START DEMO CLASSES API)------------------------------ //
  function startDemoClasses(studentapplyfordemo_id: any) {
    let formdata = new FormData();
    formdata.append("studentapplyfordemo_id", studentapplyfordemo_id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/demo_class_start`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          getdemoClasses()
          setOnetimenotify(true);
        } else {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
      })
      .catch(function (error) {
        notify(`Something went wrong ${error}`);
      });
  }

  // )------------------------------END DEMO CLASSES API)------------------------------ //
  function endDemoClasses(studentapplyfordemo_id: any) {
    let formdata = new FormData();
    formdata.append("studentapplyfordemo_id", studentapplyfordemo_id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/teacher_demo_class_end`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    setDisabledBtn(true);
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        } else {
          setDisabledBtn(false);
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
      })
      .catch(function (error) {
        setDisabledBtn(false);
        notify(error?.response?.data?.message);
      });
  }

  // )------------------------------CANCEL DEMO CLASSES API)------------------------------ //
  function cancelDemoClasses(studentapplyfordemo_id: any, demoStart_Time: any) {
    let formdata = new FormData();
    formdata.append("studentapplyfordemo_id", studentapplyfordemo_id);
    formdata.append("start_time", demoStart_Time);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/teacher_demo_class_cancel`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => { }, 3000);
          handleClose6();
        } else {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
        setTimenotify(error.response.data.message);
      });
  }

  // )------------------------------UPCOMING CLASSES API------------------------------ //
  function upcomingClasses() {
    let formdata = new FormData();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/teacher_UpcomingClasses`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.data.length === 0) {
          setloaderStatus(<div>Class Not Found...</div>);
        }
        response.data.data.forEach((item: any) => {
          setStudent_enroll_schedule_id(item?.student_enroll_schedule_id);
        })
        setData(response.data.data);
      })
      .catch(function () {
        notify("Something went wrong, Please refresh the page");
      });
  }

  // )------------------------------START DEMO/UPCOMING CLASSES API)------------------------------ //
  function startUpcomingClass(student_enroll_schedule_id: any) {
    let formdata = new FormData();
    formdata.append("student_enroll_schedule_id", student_enroll_schedule_id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/teacher_demo_class_start`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          upcomingClasses();
        } else {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
        setAssign_by_admin(response.data.assign_by_admin);
      })
      .catch(function (error) {
        setTimenotify(error.response.data.message);
      });
  }

  // )------------------------------END UPCOMING CLASSES API------------------------------ //
  function endUpcomingClass(
    studentapplyfordemo_id: any,
    student_enroll_schedule_id: any
  ) {
    let formdata = new FormData();
    formdata.append("student_enroll_schedule_id", student_enroll_schedule_id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/teacher_upcomming_class_end`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    setDisabledBtn(true);
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        } else {
          setDisabledBtn(false);
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
        setAssign_by_admin(response.data.assign_by_admin);
      })
      .catch(function (error) {
        setDisabledBtn(false);
        // notify(error?.response?.data?.message);
        setTimenotify(error.response.data.message);
      });
  }

  // ------------------------------PAST CLASSES API)------------------------------ //
  function pastClasses() {
    let formdata = new FormData();

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/past_request_all`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        // console.log(response, "resp876")
        if (response.data.data.length === 0) {
          setloaderStatus(<div>Class Not Found...</div>);
        }
        setData(response?.data?.data);
        setPastData(response?.data?.data);
        response.data.data.forEach((item: any) => {
          setStudent_enroll_schedule_id(item?.student_enroll_schedule_id);
        })
      })
      .catch(function (error) {
        notify("Something went wrong, Please refresh the page and try again later");
      });
  }

  // ----------------------------- TEACHER CONTACT SUPPORT API -----------------------------//
  function demoClsFeedback() {
    console.log(contactSupports, "contactSupports")
    let formdata = new FormData();
    formdata.append("enroll_id", contactSupports.enroll_id);
    formdata.append("student_id", contactSupports.student_id);
    formdata.append("message", contactSupports.message);
    formdata.append("phone", localData?.phone);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/teacher_contact_support`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
        if (response.data.statusCode == 200) {
          handleClose();
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        } else {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
        setTimenotify(error.response.data.message);
      });
  }

  // -----------------------------UPLOAD HOMEWORK API -----------------------------//
  function uploadHomework(file: any) {
    file.preventDefault()
    let formdata = new FormData();
    formdata.append("enroll_id", enroll_id);
    formdata.append("student_id", student_id);
    formdata.append("student_enroll_schedule_id", student_enroll_schedule_id);
    formdata.append("assignment", file.target.files[0]);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/add_assignment`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        file.target.value = null;
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
        if (response.data.statusCode == 200) {
          handleClose5();
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        } else {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
        setTimenotify(error.response.data.message);
      });
  }

  // -----------------------------ZOOM LINK FOR DEMO CLASS API -----------------------------//
  function demoZoom(studentapplyfordemo_id: any, link: any) {
    let formdata = new FormData();
    formdata.append("studentapplyfordemo_id", studentapplyfordemo_id);
    formdata.append("zoom_link", link);
    formdata.append("teacher_id", localData?.id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/zoom_link_update`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        getdemoClasses();
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
        if (response.data.statusCode == 200) {
          setZoom_link(response.data.data.zoom_link)
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        } else {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
        setTimenotify(error.response.data.message);
      });
  }

  // -----------------------------ZOOM LINK FOR DEMO CLASS API -----------------------------//
  function upcommingZoom(student_enroll_schedule_id: any, classLink: any, class_topic: any) {
    let formdata = new FormData();
    formdata.append("student_enroll_schedule_id", student_enroll_schedule_id);
    formdata.append("zoom_link", classLink);
    formdata.append("class_topic", class_topic);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/enroll_zoom_link_update`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        upcomingClasses();
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
        if (response.data.statusCode == 200) {
          handleClose5();
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        } else {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
        setTimenotify(error.response.data.message);
      });
  }

  // -----------------------------RESCHEDULE API DEMO (NOTIFICATIONS) -----------------------------//
  function reschedule() {
    let formdata = new FormData();
    formdata.append("user_id", user_id);
    formdata.append("demo_id", student_enroll_schedule_id);
    formdata.append("teacher_id", localData?.id);
    formdata.append("utcdate", start_time);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/teacher_democlass_reschedule`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          handleClose4()
          setTimeout(() => {
            navigate.push({
              pathname: "/teacher/home",
              state: { detail: response.data.id },
            });
          }, 1000);
        } else {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
      })
      .catch(function () {
        notify("Something went wrong, Please refresh the page or again later");
      });
  }

  // -----------------------------RESCHEDULE API DEMO (NOTIFICATIONS) -----------------------------//
  function rescheduleUpcoming() {
    let formdata = new FormData();
    formdata.append("user_id", user_id);
    formdata.append("student_enroll_schedule_id", student_enroll_schedule_id);
    formdata.append("teacher_id", localData?.id);
    formdata.append("utcdate", start_time);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/teacher_class_reschedule`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          handleClose7()
          setTimeout(() => {
            navigate.push({
              pathname: "/teacher/home",
              state: { detail: response.data.id },
            });
          }, 1000);
        } else {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
      })
      .catch(function () {
        notify("Something went wrong, Please try again later");
      });
  }

  function handleZoomDemo(id: number) {
    demoZoom(id, zoom_link);
  }

  function tConvert(time: any) {
    time = time?.toString()?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time?.slice(1);
      time[5] = +time[0] < 12 ? 'AM' : 'PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (location?.title == "Home Work") {
      pastClasses();
    } else if (location?.title == "Class assign" || location?.title == "Demo Cancel Class" || location?.title == "Demo Reschedule Class") {
      getdemoClasses();
    } else if (location?.title == "Enroll Reschedule Class" || location?.state == undefined || location?.state?.detail == undefined) {
      upcomingClasses();
    }
  }, []);

  const [reciverId, setReciverId] = useState<any>(null);
  const [recivedMsgs, setRecivedMsgs] = useState<any>([]);

  // -------------------------------------------- UPCOMING CLASS API --------------------------------------------//
  function teacher_upcomming_past_data() {
    let formdata = new FormData();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/teacher_upcomming_past_data`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        const chatNames = response?.data?.data?.filter((obj: any, index: any) =>
          response.data.data.findIndex((event: any) => event.teacher_id == obj.teacher_id) == index
        );
        const filterItemArr: any = [];
        let allChatID: any = []
        chatNames.map((filterItem: any) => {
          allChatID?.push(localData?.role_id == 6 ? `${filterItem?.teacher_id}_${localData?.id}` : `${filterItem?.student_id}_${localData?.id}`)
          setReciverId(localData?.role_id == 6 ? filterItem?.teacher_id : filterItem?.student_id)
          filterItemArr?.push(localData?.role_id == 6 ? filterItem?.teacher_id : filterItem?.student_id);
        })
      })
      .catch(function (error) {
        console.log(error, 'error');
      });
  }

  useEffect(() => {
    teacher_upcomming_past_data();
  }, [])

  // {UNREAD MESSAGE FUNCTIONALITY}
  let trueValues: any[] = [];
  if (trueValues?.length === 0) {
    recivedMsgs?.forEach((item: any) => {
      if (item?.seen == false) {
        trueValues?.push(item?.seen);
      }
    });

  }

  const reciversChatId = `${reciverId}_${localData?.id}`

  // -------------------------------------- FOR RECIVER -------------------------------------
  useEffect(() => {
    if (reciverId) {
      const q = query(
        collection(db, 'messages', 'solo-message', reciversChatId),
        orderBy('createdAt', 'desc'),
        limit(50)
      );
      const unsubscribe: Unsubscribe = onSnapshot(q, (QuerySnapshot) => {
        const fetchedMessages: any = [];
        QuerySnapshot.forEach((doc) => {
          fetchedMessages.push({ ...doc.data(), id: doc?.id });
        });
        const sortedMessages = fetchedMessages.sort(
          (a: any, b: any) => a.createdAt - b.createdAt
        );
        setRecivedMsgs(sortedMessages);
      });
      return unsubscribe;
    }

  }, [reciversChatId]);

  return (
    <>
      <ToastContainer limit={1} closeButton={true} />
      <Header count={notificatCount} chatCount={trueValues?.length} />
      <section className={`${cx.slider_mainSection} ${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <div className={`${cx.searchBox}`}>
              </div>
              <NavLink to="/teacher/chats" className={`${cx.notification}`}>
                <AiFillMessage />
                {trueValues?.length > 0 ? <span>{trueValues?.length}</span> : ''}
              </NavLink>
              <NavLink to="/teacher/basic-detail" className={`${cx.notification}`} >
                <HiDocumentCheck />
                {classCount?.classCount > 0 ? <span>{classCount?.classCount}</span> : ''}
              </NavLink>
              <NavLink to="/teacher/notification" className={`${cx.notification}`} >
                <MdNotifications />
                {homeNotify?.notifyState > 0 ? <span>{homeNotify?.notifyState}</span> : ''}
              </NavLink>
              <NavLink to="/teacher/my-earning" className={`${cx.wallet}`}>
                <FaWallet />
              </NavLink>
            </div>
          </div>
          <div className={`${cx.spaceBox}`}></div>

          <div className="container">
            <div>
              <div className={`${cx.swiperSelect_main}`}>
                <div className={`${cx.swiperSelect_details}`}>
                  <Swiper
                    onSwiper={setThumbsSwiper}
                    initialSlide={location?.state?.index}
                    onActiveIndexChange={setvisibleSlidesIndexes}
                    spaceBetween={10}
                    navigation={false}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper2"
                    onSlideChange={handleSlideChange}
                  >
                    <SwiperSlide>
                      <div key={1}>
                        <Row>
                          {data?.length === 0 && (
                            <div className="d-flex justify-content-center vh-100">
                              {loaderStatus}
                            </div>
                          )}
                          {data?.map((item: any) => {
                            console.log(item, "woieiwoirwgoiw8989889898")
                            let plus20mins = new Date(item?.utcdate);
                            plus20mins.setMinutes(plus20mins.getMinutes() + 20);
                            plus20mins = new Date(plus20mins); // Date object
                            let localTime1 = new Date(`${item?.utc_date_time || item?.utcdate}`).toLocaleString("en-US", { timeZone: localData?.timezone });
                            let splitTIme = localTime1.split(",");
                            let dateToMill = new Date()
                            let millsec = dateToMill.getTime()
                            let millisec = plus20mins.getTime();
                            let date = new Date().toISOString();
                            let newDate = date?.split("T");
                            // console.log(localTime1, 'hahahahahjjfjf4567890')
                            // CONVERT TO MILLISECONDS
                            // let dateToMill2 = new Date()
                            // let millsec2 = dateToMill2.getTime()
                            // let localTime = new Date(`${item?.select_date} ${item?.start_time} UTC`).toLocaleString("en-US", { timeZone: localData?.timezone });
                            // console.log(localTime, 'localTime')
                            // CONVERT MILLISECONDS END
                            // let getDate = newDate[0]?.split("-");
                            // let getTime = newDate[1]?.split(":");
                            // let fullTime = item?.select_date + "T" + item?.start_time + "0Z"
                            // let final = fullTime?.split("T");
                            // let finalDate: any = final[0]?.split("-");
                            // let finalTime: any = final[1]?.split(":");
                            // Reschdule
                            // let date: any = new Date().toISOString(); //THIS ONE 
                            // let newDate = date.split("T");
                            let getDateO = newDate[0]
                            let getTimeO = newDate[1].slice(0, 8)
                            let mergedDateAndTime = item?.select_date + " " + item?.start_time
                            let finalUtcTime = getDateO + " " + getTimeO
                            let date1: any = new Date(finalUtcTime)
                            let date2: any = new Date(mergedDateAndTime);
                            let dif: any = Math.abs(date2 - date1) / 1000;
                            return (
                              <Col lg={6}>
                                <div className={`${cx.classCard}`}>
                                  <ul>
                                    <li className="align-items-end">
                                      <span onClick={() => {
                                        handleStudentDetailsShow();
                                        setUpcomingData(item);
                                      }}>
                                        <div className={`${cx.profileName}`}>
                                          {/* <img className={`${cx.imgClass}`} src={item?.student_picture || user} /> */}
                                          {item?.student_picture == "" || item?.student_picture == null ?
                                            <ProfileAvatar name={item?.student_name} />
                                            : <img alt="student" className={`${cx.imgClass}`} src={item?.student_picture} />}
                                          <div className={`${cx.profileName_details}`}>
                                            <h5>{item?.student_name}
                                            </h5>
                                            {/* <button className="btn"
                                            >View Profile</button> */}
                                          </div>
                                        </div>
                                      </span>
                                      <span className={`${st.themeColor}`}>
                                        Date - {item?.select_date == "yy-mm-dd" ? "yy-mm-dd" : splitTIme[0]}
                                      </span>
                                    </li>
                                    <li>
                                      <span>{item?.subject_name ? 'Subject' : 'Course'} - {item?.subject_name || item?.course_name}</span>
                                      <span>Start Time - {item?.select_date == "yy-mm-dd" ? "hh-mm" : splitTIme[1]}</span>
                                    </li>
                                    <li>
                                      <span>{item?.class_name ? 'Class' : 'Category'}- {item?.class_name ?? item?.category_name}</span>
                                    </li>
                                    <li>
                                      Class Url-
                                      <div className={`position-relative`}>
                                        <input type="text" placeholder="Add a Class link" defaultValue={item?.zoom_link == null ? item?.zoome_class_link : item?.zoom_link}
                                          onBlur={(e) => {
                                            setZoom_link(e.target.value)
                                            upcommingZoom(item?.student_enroll_schedule_id, e.target.value, class_topic)
                                          }} />
                                        <AiFillEdit />
                                      </div>
                                    </li>
                                    <li>
                                      Class Topic-
                                      <div className={`position-relative`}>
                                        <input type="text" placeholder="Add a Topic" defaultValue={item?.class_topic}
                                          onBlur={(event) => {
                                            setClass_topic(event.target.value)
                                            upcommingZoom(item?.student_enroll_schedule_id, zoom_link, event.target.value)
                                          }} />
                                        <AiFillEdit />
                                      </div>
                                    </li>
                                  </ul>
                                  <Row>
                                    {/* <Col lg={4} className="mt-2 col-6">
                                      <button
                                        disabled={item?.cancel_status == 1 ? true : false}
                                        className="btn"
                                        onClick={() => {
                                          chatLenght(item?.chat_lenght?.student_length)
                                          navigate.push("/teacher/chat", {
                                            item,
                                          });
                                        }}>Chat
                                        <strong style={{ color: "white", padding: "10px", position: "relative" }}>
                                          {item?.chat_lenght?.teacher_length >= 1 ? <BsFillChatLeftFill /> : ""}
                                        </strong>
                                      </button>
                                    </Col> */}
                                    <Col lg={6} className="mt-2 col-6">
                                      <Button
                                        // target="_blank"
                                        disabled={item?.enroll_class_start_status == 1 || item?.cancel_status == 1 ? true : false}
                                        onClick={() => {
                                          // if (enroll_class_start_status || student_demo_class_cancel || cancel_status == 1) {
                                          //   setDisable(true);
                                          // }
                                          //  CONVERT MILLISECONDS
                                          // // CONVERT MILLISECONDS END
                                          let date = new Date().toISOString();
                                          let newDate = date?.split("T");
                                          let getDate = newDate[0]?.split("-");
                                          let getTime = newDate[1]?.split(":");
                                          let fullTime = item?.select_date + "T" + item?.start_time + "0Z"
                                          let final = fullTime?.split("T");
                                          let finalDate: any = final[0]?.split("-");
                                          let finalTime: any = final[1]?.split(":");
                                          let link = item?.class_url || item?.zoom_link;
                                          if (Number(`${getDate[0]}${getDate[1]}${getDate[2]}${getTime[0]}${getTime[1]}`) >= Number(`${finalDate[0]}${finalDate[1]}${finalDate[2]}${finalTime[0]}${finalTime[1]}`) && item?.class_end_status == 0) {
                                            setTimeout(() => {
                                              let audio = new Audio("https://appicsoftwares.in/guruCors/public/images/endClassBuzzer.mp3");
                                              audio.play();
                                            }, 3300000);
                                            if (millisec < millsec) {
                                              notify("Oops! It appears you've missed this session. Please contact the admin for further assistance.");
                                            } else {
                                              if (link && link.startsWith('https://')) {
                                                link = link.replace('https://', '');
                                              }
                                              startUpcomingClass(item?.student_enroll_schedule_id);
                                              window.open(`//${link}`);
                                            }
                                          }
                                          else {
                                            notify(
                                              "Its too early to start"
                                            );
                                          }
                                        }
                                        }>Start Session
                                      </Button>
                                    </Col>
                                    <Col lg={6} className="mt-2 col-6">
                                      <Button
                                        className="btn"
                                        disabled={item?.enroll_class_end_status == 1 || item?.student_demo_class_cancel == 1 || item?.cancel_status == 1 ||
                                          item?.enroll_class_start_status == 0 ? true : false || disabledBtn}
                                        onClick={() => {
                                          // if (enroll_class_end_status || enroll_class_start_status || student_demo_class_cancel || cancel_status == 1) {
                                          //   setDisable(true);
                                          // }
                                          //  CONVERT MILLISECONDS 
                                          let dateToMill = new Date()
                                          let millsec = dateToMill.getTime()
                                          // CONVERT MILLISECONDS END
                                          // let date = new Date().toISOString();
                                          // let newDate = date?.split("T");
                                          // let fullTime = item?.select_date + "T" + item?.start_time + "0Z"
                                          // let final = fullTime?.split("T")
                                          // let getDate = newDate[0]?.split("-");
                                          // let getTime = newDate[1]?.split(":")
                                          // let finalDate: any = final[0]?.split("-")
                                          // let finalTime: any = final[1]?.split(":")
                                          // if (Number(`${getDate[0]}${getDate[1]}${getDate[2]}${getTime[0]}${getTime[1]}`) >= Number(`${finalDate[0]}${finalDate[1]}${finalDate[2]}${finalTime[0]}${finalTime[1]}`)) {
                                          if (millisec < millsec && item?.enroll_class_start_status == 0) {
                                            notify("Oops! It appears you've missed this session. Please contact the admin for further assistance.");
                                          } else {
                                            endUpcomingClass(item?.studentapplyfordemo_id || item?.student_apply_for_demo_id, item?.student_enroll_schedule_id);
                                            setTimeout(() => {
                                              upcomingClasses();
                                              navigate.push({
                                                pathname: "/teacher/home"
                                              });
                                            }, 1000);
                                          }
                                          // }
                                          // else {
                                          //   notify(
                                          //     "You can not end class before started"
                                          //   );
                                          // }
                                        }}>End Session
                                      </Button>
                                    </Col>
                                    <Col lg={6} className="mt-2 col-6">
                                      <Button
                                        disabled={dif <= 10800 ? true : false || item?.cancel_status == 1 || item?.enroll_class_start_status == 1 ? true : false}
                                        className="btn"
                                        onClick={() => {
                                          handleShow7()
                                          setStart_time(item?.utc_date_time || item?.utcdate);
                                          setUserId(item?.user_id);
                                        }}>Reschedule
                                      </Button>
                                    </Col>
                                    <Col lg={6} className="mt-2 col-6">
                                      <button
                                        className="btn"
                                        onClick={() => {
                                          console.log(item, "itemitem")
                                          setContactSupports({
                                            enroll_id: item?.enroll_id,
                                            student_id: item?.student_id,
                                            message: ""
                                          });
                                          handleShow();
                                          // setContactSupports('');
                                        }}>
                                        Contact Support
                                      </button>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div>
                        <Row>
                          {data?.length === 0 && assign_by_admin?.length === 0 && (
                            <div className="d-flex justify-content-center vh-100">
                              {loaderStatus}
                            </div>
                          )}
                          {data?.map((item: any) => {

                            const dates: any[] = []
                            for (let i = 0; i < item?.student_preference?.length; i++) {
                              const datechangebasedontime = item?.student_preference?.[i]?.utc_select_date
                              const newtimezone = mtimezone(datechangebasedontime).tz(localData?.timezone).format();
                              const newDate = newtimezone?.split("T")[0]
                              const newtime = newtimezone?.split("T")[1]?.slice(0, 5)
                              const sliceTime = newtime?.slice(3)
                              const endTime = +newtime?.slice(0, 2) + 1
                              dates.push({
                                "dateTime": datechangebasedontime,
                                "date": newDate,
                                "time": newtime,
                                "endTime": endTime + ":" + sliceTime
                              })
                            }
                            const dateString = dates?.[0]?.date;
                            const parts = dateString?.split("-");
                            const formattedDate = `${parts?.[1]}/${parts?.[2]}/${parts?.[0]}`;
                            return (
                              <Col lg={4}>
                                <div className={`${cx.classCard}`}>
                                  <ul>
                                    <li>
                                      <div className={`${cx.profileName}`} onClick={() => {
                                        handleDemoStudentDetailsShow();
                                        setDemoDetails(item);
                                      }}>
                                        {item?.profile_img == "" || item?.profile_img == null ?
                                          <ProfileAvatar name={item?.student_name} />
                                          : <img alt="profile" className={`${cx.imgClass}`} src={item?.profile_img} />}
                                        <div className={`${cx.profileName_details}`}>
                                          <h5>{item?.student_name}
                                          </h5>
                                          {/* <button className="btn"
                                          >View Profile</button> */}
                                        </div>
                                      </div>
                                      <span>Subject- {item.subject_name}</span>
                                    </li>
                                    <li>
                                      {/* <span>
                                  <h5>Name-
                                    <button>{item?.student_name}</button>
                                  </h5>
                                </span> */}
                                    </li>
                                    <li>
                                      <span>Start Time- {tConvert(dates[0]?.time)}</span>
                                      <span>
                                        Date-{" "}
                                        {formattedDate ?? "Not Available..."}
                                      </span>
                                    </li>
                                    <li>
                                      <span> Preferred Topic- {item.preferred_topic ? item.preferred_topic : "Not Available..."}</span>
                                      <span>Curriculum - {item.curriculum}</span>
                                    </li>
                                    <li>
                                      <span>Class- {item?.class_name}</span>
                                    </li>
                                    <li>
                                      Class Url-
                                      <div className={`position-relative`}>
                                        <input
                                          type="text"
                                          placeholder="Add a Class link"
                                          defaultValue={item?.zoom_link}
                                          onChange={(e: any) => {
                                            setZoom_link(e.target.value);
                                          }}
                                          disabled={classLinkEditS}
                                        />
                                        {classLinkEditS && (
                                          <AiFillEdit className={`${cx.actionIcon}`}
                                            onClick={() => {
                                              setClassLinkEditS(!classLinkEditS);
                                            }}
                                          />
                                        )}
                                        {!classLinkEditS && (
                                          <TiTick className={`${cx.actionIcon}`}
                                            onClick={() => {
                                              setClassLinkEditS(!classLinkEditS);
                                              handleZoomDemo(
                                                item?.studentapplyfordemo_id
                                              );
                                            }}
                                          />
                                        )}
                                      </div>
                                    </li>
                                  </ul>
                                  <Row>
                                    <Col>
                                      <Button
                                        className="btn"
                                        disabled={item?.apply_for_demo_status == 1 || Date.now() > Date.parse(dates[0]?.dateTime)}
                                        onClick={() => {
                                          navigate.push("/teacher/apply", {
                                            item: item,
                                            time: dates,
                                          });
                                        }}>
                                        Apply
                                      </Button>
                                    </Col>
                                    {/* <Col md={12}>
                                <Button className="btn mt-2" onClick={handleTeacherRatingShow}>Feaadback</Button>
                              </Col> */}
                                  </Row>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>

                        <Row>
                          {assign_by_admin?.map((item: any) => {
                            // let localTime1 = new Date(`${item?.select_date}`).toLocaleString("en-US", { timeZone: localData?.timezone });
                            let plus20mins = new Date(item?.select_date)
                            plus20mins.setMinutes(plus20mins.getMinutes() + 20); // timestamp
                            plus20mins = new Date(plus20mins); // Date object
                            // CONVERT TO MILLISECONDS
                            let millisec = plus20mins.getTime();
                            let datechangebasedontime = item?.select_date
                            let newtimezone = mtimezone(datechangebasedontime).tz(localData?.timezone).format();
                            let newtime = newtimezone.split("T")[1]?.slice(0, 5)
                            let localTime = new Date(`${item?.select_date}`).toLocaleString("en-US", {
                              timeZone: localData?.timezone,
                              month: '2-digit',
                              day: '2-digit',
                              year: 'numeric',
                            });
                            let utcDate = localTime?.split(",")
                            return (
                              <Col lg={4}>
                                <div className={`${cx.classCard}`}>
                                  <ul>
                                    <li>
                                      <div className={`${cx.profileName}`} onClick={() => {
                                        handleAssignStudentDetailsShow();
                                        setAssignByAdmin(item);
                                      }}>
                                        {/* <img className={`${cx.imgClass}`} src={item?.profile_img || user} /> */}
                                        {item?.profile_img == "" || item?.profile_img == null ?
                                          <ProfileAvatar name={item?.student_name} />
                                          : <img alt="profile" className={`${cx.imgClass}`} src={item?.profile_img} />}
                                        <div className={`${cx.profileName_details}`}>
                                          <h5>{item?.student_name}
                                          </h5>
                                          {/* <button className="btn"
                                          >View Profile</button> */}
                                        </div>
                                      </div>
                                      <span>Subject- {item.subject_name}</span>
                                    </li>
                                    {/* <li>
                                <span>
                                  <h5>Student-
                                    <button>{item?.student_name}</button>
                                  </h5>
                                </span>
                                <span>Subject-{item?.subject_name}</span>
                              </li> */}
                                    <li>
                                      <span>Start Time- {tConvert(newtime)}</span>
                                      <span>Date- {utcDate[0]}</span>
                                      {/* <span>Date- {item?.select_date.slice(0, 10)}</span> */}
                                    </li>
                                    <li>
                                      <span>Preferred Topic - {item?.preferred_topic}</span>
                                      <span>Curriculum - {item?.curriculum}</span>
                                    </li>
                                    <li>
                                      <span>Class - {item?.class_name}</span>
                                    </li>
                                    <li>
                                      Class Url-
                                      <div className={`position-relative`}>
                                        <input type="text" placeholder="Add a Class link" defaultValue={item?.zoomLink || item?.zoom_link}
                                          disabled={item?.class_end_status || item?.cancel_by_teacher_status ? true : false}
                                          onBlur={(e: any) => {
                                            // setZoomLink(e.target.value)
                                            demoZoom(item?.studentapplyfordemo_id, e.target.value)
                                          }} />
                                        <AiFillEdit className={`${cx.actionIcon}`} />
                                      </div>
                                    </li>
                                  </ul>
                                  <Row>
                                    <Col className="col-6">
                                      <Button
                                        // target="_blank"
                                        className="btn"
                                        // disabled={item?.class_end_status || item?.class_start_status || item?.student_demo_class_cancel || item?.cancel_by_teacher_status == 1 ? true : false}
                                        onClick={() => {
                                          // if (class_end_status == 1 || class_start_status == 1 || student_demo_class_cancel == 1) { setDisable(true); }
                                          let date: any = new Date().toISOString();
                                          //  CONVERT MILLISECONDS 
                                          let dateToMill = new Date();
                                          let millsec = dateToMill.getTime();
                                          // CONVERT MILLISECONDS END
                                          let newDate = date.split("T");
                                          let getDate = newDate[0].split("-");
                                          let getTime = newDate[1].split(":");
                                          let final = item?.select_date?.split("T");
                                          let finalDate: any = final[0].split("-");
                                          let finalTime: any = final[1].split(":");
                                          let link = item?.class_url || item?.zoom_link

                                          console.log(millisec, millsec, "millsecmillsecmillsec")
                                          if (Number(`${getDate[0]}${getDate[1]}${getDate[2]}${getTime[0]}${getTime[1]}`) >= Number(`${finalDate[0]}${finalDate[1]}${finalDate[2]}${finalTime[0]}${finalTime[1]}`) && item?.class_end_status == 0) {
                                            setTimeout(() => {
                                              let audio = new Audio("https://appicsoftwares.in/guruCors/public/images/endClassBuzzer.mp3");
                                              audio.play();
                                            }, 3300000);
                                            if (millisec < millsec) {
                                              notify("Oops! It appears you've missed this session. Please contact the admin for further assistance.");
                                            } else {
                                              if (link && link.startsWith('https://')) {
                                                link = link.replace('https://', '');
                                              }
                                              startDemoClasses(item?.studentapplyfordemo_id);
                                              window.open(`//${link}`)
                                              // setTimeout(() => {
                                              //   navigate.push({
                                              //     pathname: "/teacher/home",
                                              //   });
                                              // }, 3000);
                                              // <a target="_blank" href={`//${item?.zoom_link}`}>Start Class</a>
                                            }
                                          }
                                          else {
                                            notify(
                                              "Its too early to start"
                                            );
                                          }
                                        }}> Start Session
                                      </Button>
                                    </Col>
                                    <Col className="col-6">
                                      <Button
                                        className="btn"
                                        disabled={item?.class_end_status || !item?.class_start_status || item?.student_demo_class_cancel ||
                                          item?.cancel_by_teacher_status == 1 ? true : false || disabledBtn}
                                        onClick={() => {
                                          // if (class_end_status || student_demo_class_cancel == 1) {
                                          //   setDisable(true);
                                          // }
                                          endDemoClasses(item?.studentapplyfordemo_id);
                                          setTimeout(() => {
                                            getdemoClasses();
                                            navigate.push({
                                              pathname: "/teacher/home",
                                            });
                                          }, 2000);
                                        }}
                                      >End Session
                                      </Button>
                                    </Col>
                                    <Col className="mt-3 col-6">
                                      <Button
                                        className="btn"
                                        disabled={item?.class_end_status || item?.student_demo_class_cancel || item?.class_start_status || item?.cancel_by_teacher_status == 1 ? true : false}
                                        onClick={() => {
                                          // if (class_end_status || student_demo_class_cancel || class_start_status || cancel_by_teacher_status == 1) {
                                          //   setDisable(true);
                                          // }
                                          setStudent_enroll_schedule_id(item?.id);
                                          handleShow4();
                                          // reschedule(item?.student_enroll_schedule_id, item?.student_id)
                                          // setEnrollID(item?.student_enroll_schedule_id)
                                        }}
                                      >Reschedule
                                      </Button>
                                    </Col>
                                    <Col className="mt-3 col-6">
                                      <Button
                                        className="btn"
                                        disabled={item?.cancel_by_teacher_status || item?.class_start_status || item?.student_demo_class_cancel || item?.class_end_status == 1 ? true : false}
                                        onClick={() => {
                                          handleShow3();
                                          handleShow6();
                                        }}>
                                        Cancel
                                      </Button>
                                    </Col>
                                    <Col md={12}>
                                      <Button className="btn mt-2" onClick={() => {
                                        handleTeacherRatingShow();
                                        setFeedback(item?.teacher_feedback)
                                      }}>Feedback</Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </SwiperSlide>

                    <SwiperSlide>
                      <div>
                        <Row>
                          {pastData?.length === 0 && (
                            <div className="d-flex justify-content-center vh-100">
                              {loaderStatus}
                            </div>
                          )}
                          {pastData?.map((item: any) => {
                            let localTime = new Date(`${item?.select_date} ${item?.start_time} UTC`).toLocaleString("en-US", { timeZone: localData?.timezone });
                            let dateTime = localTime?.split(',');
                            return (
                              <Col lg={4}>
                                <div className={`${cx.classCard}`}>
                                  <ul>
                                    <li>
                                      <span>
                                        <h5>{item?.student_name}</h5>
                                      </span>
                                      <span className={`${st.themeColor}`}>
                                        Date - {dateTime[0]}
                                      </span>
                                    </li>
                                    <li>
                                      <span>
                                        <h6>{item?.class_name ? 'Class' : 'Category'}- {item?.class_name || item?.category_name}</h6>
                                      </span>
                                      <span className={`${st.themeColor}`}>
                                        Time - {dateTime[1]}
                                      </span>
                                    </li>
                                    <li>
                                      <h6>Class Topic- {item?.class_topic}</h6>
                                      <span>{item?.subject_name ? 'Subject' : 'Course'} - {item?.subject_name || item?.courses_name}</span>
                                    </li>
                                    {/* <li>
                                <span>{item?.subject_name ? "Subject" : "Course"} - {item?.subject_name || item?.courses_name}</span>
                                <span>
                                  <Button style={{ display: "flex", gap: "15px" }}
                                    onClick={() => {
                                      chatLenght(item?.chat_lenght?.student_length)
                                      navigate.push("/teacher/chat", {
                                        item,
                                      });
                                    }}>
                                    Chat
                                    <strong style={{ color: "white", padding: "10px", position: "relative", display: "flex" }}>
                                      {item?.chat_lenght?.teacher_length != 0 ? <BsFillChatLeftFill /> : ""}
                                    </strong>
                                  </Button>
                                </span>
                              </li> */}
                                  </ul>
                                  <Row className={`align-items-center`}>
                                    <Col className={`${cx.btnInput}`} >

                                      <Button className="btn" type="button"
                                        onClick={() => {
                                          setEnroll_id(item?.enroll_id);
                                          setStudent_id(item?.student_id);
                                          setStudent_enroll_schedule_id(item?.student_enroll_schedule_id);
                                        }}>
                                        <input type="file"
                                          onChange={uploadHomework}
                                        />
                                        Upload <br /> Assignment
                                      </Button>
                                    </Col>
                                    <Col>
                                      <Button className="btn" type="button"
                                        onClick={() => {
                                          handleShow5()
                                          setteacher_assignment_pdf(item?.teacher_assignment_pdf)
                                        }}>
                                        Download Assignment
                                      </Button>
                                    </Col>
                                    <Col md={12}>
                                      <Button className="btn mt-2" onClick={() => {
                                        handleTeacherRatingShow();
                                        setFeedback(item?.teacher_feedback)
                                      }}>Feedback</Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                    </SwiperSlide>

                  </Swiper>
                </div>

                <div className={`${cx.swiperSelect_pillBox}`}>
                  <Swiper
                    onSwiper={setThumbsSwiper}
                    initialSlide={location?.state?.index}
                    onActiveIndexChange={setvisibleSlidesIndexes}
                    spaceBetween={10}
                    slidesPerView={3}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="mySwiper"
                    onSlideChange={handleSlideChange}
                  >
                    <SwiperSlide>
                      <div className={`${cx.swiperSelect_pill_btn}`}
                        onClick={() => {
                          setloaderStatus(<ReactLoading type="cylon" color="blue" />)
                          setData([])
                          upcomingClasses();
                        }}>
                        Upcoming
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className={`${cx.swiperSelect_pill_btn}`}
                        onClick={() => {
                          setloaderStatus(<ReactLoading type="cylon" color="blue" />)
                          setData([])
                          getdemoClasses();
                        }}>
                        Demo
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className={`${cx.swiperSelect_pill_btn}`}
                        onClick={() => {
                          setloaderStatus(<ReactLoading type="cylon" color="blue" />)
                          setData([])
                          pastClasses();
                        }}>
                        Past
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
          <div className={`${st.responsive_bottom_height}`}></div>
        </div>


        <Bottombar />
      </section>

      <Footer />

      {/* CONTACT SUPPORT POPUP */}
      <div className={`${cx.customerBox} ${show && cx.popupShow}`}>
        <div className={`${cx.bgBox}`}></div>
        <div className={`${cx.popupBoxBody} ${cx.contactSupport}`}>
          <div className={`${cx.popupTitle}`}>
            <h5>
              Contact Support
              <i className={`${cx.closeIcon}`} onClick={() => {
                handleClose();
              }}>
                <MdOutlineClose />
              </i>
            </h5>
          </div>
          <div className={`${cx.cotentBody}`}>
            <Row>
              <Col className="col-12">
                <Form onSubmit={handleSubmit(demoClsFeedback)} className={`${st.formFull}`} >
                  <Form.Group className={`${st.formField}`} controlId="">
                    <Form.Control
                      {...register("contactSupports", {
                        required: "This field is required",
                      })}
                      as="textarea"
                      placeholder="Message"
                      style={{ height: "100px" }}
                      onChange={(e: any) => {
                        setContactSupports((prev: any) => {
                          return { ...prev, message: e.target.value };
                        });
                      }}
                    />
                    {errors?.contactSupports?.type === "required" && (
                      <p style={{ color: "red" }}>This field is required</p>
                    )}
                  </Form.Group>
                  <Col className="col-12">
                    <button className={`btn mt-2 ${cx.bookBtn}`} type="submit">
                      Submit
                    </button>
                  </Col>
                  <a style={{ textDecoration: 'none', color: 'black' }} target="_blank" href="https://api.whatsapp.com/send?phone=918440868293">
                    <p className={` ${cx.whatsAppBtn}`}>WhatsApp Support
                      <a target="_blank" href="https://api.whatsapp.com/send?phone=918440868293"><IoLogoWhatsapp /></a>
                    </p>
                  </a>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {/* DEMO CLASS CANCEL */}
      <div className={`${cx.popupBox} ${show6 && cx.popupShow}`}>
        <div className={`${cx.bgBox}`}></div>
        <div className={`${cx.popupBoxBody}`}>
          <div className={`${cx.cotentBody}`}>
            <Row>
              <Col className="col-12">
                <h5>Do you want to Cancel the class?</h5>
              </Col>
              <Col className={`col-12 ${cx.marginMin}`}>
                <NavLink
                  to="#"
                  className={`btn border-end ${cx.actionBtn}`}
                  onClick={handleClose6}>
                  NO
                </NavLink>
                <Button
                  className={`btn ${cx.actionBtn}`}
                  onClick={() => {
                    cancelDemoClasses(studentapplyfordemo_id, demoStart_Time);
                    setTimeout(() => {
                      getdemoClasses();
                      navigate.push({
                        pathname: "/teacher/home",
                      });
                    }, 2000);
                  }}>
                  YES
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {/* POPUP UPCOMING RESCHEDULE */}
      <div className={`${cx.popupBox} ${show7 && cx.popupShow}`}>
        <div className={`${cx.bgBox}`}></div>
        <div className={`${cx.popupBoxBody}`}>
          <div className={`${cx.cotentBody}`}>
            <Row>
              <Col className="col-12">
                <h5>Do you want to Reschedule the class?</h5>
              </Col>
              <Col className={`col-12 ${cx.marginMin}`}>
                <NavLink
                  to="#"
                  className={`btn border-end ${cx.actionBtn}`}
                  onClick={handleClose7}>
                  NO
                </NavLink>
                <Button className={`btn ${cx.actionBtn}`}
                  onClick={() => {
                    rescheduleUpcoming()
                  }}>YES</Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {/* POPUP DEMO RESCHDULE */}
      <div className={`${cx.popupBox} ${show4 && cx.popupShow}`}>
        <div className={`${cx.bgBox}`}></div>
        <div className={`${cx.popupBoxBody}`}>
          <div className={`${cx.cotentBody}`}>
            <Row>
              <Col className="col-12">
                <h5>Do you want to Reschedule the class?</h5>
              </Col>
              <Col className={`col-12 ${cx.marginMin}`}>
                <NavLink
                  to="#"
                  className={`btn border-end ${cx.actionBtn}`}
                  onClick={handleClose4}>
                  NO
                </NavLink>
                <Button className={`btn ${cx.actionBtn}`}
                  onClick={() => {
                    reschedule()
                  }}>YES</Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {/* HOMEWORK */}
      <div className={`${cx.popupBox} ${show5 && cx.popupShow}`}>
        <div className={`${cx.bgBox}`}></div>
        <div className={`${cx.popupBoxBody}`}>
          <div className={`${cx.cotentBody}`}>
            <Row>
              <Col className="col-12">
                <div className={`${cx.homeworkHeading}`}>
                  <h3>Homework</h3>
                  <i className={`${cx.closeIcon}`} onClick={handleClose5}>
                    <MdOutlineClose />
                  </i>
                </div>

                <div className={`${cx.homeworkScroll}`} >
                  {teacher_assignment_pdf?.map((item: any) => {
                    return (
                      <div className={`${cx.homeworkDownload}`}>
                        {item?.student_assignment_submit_pdf ? <span>
                          <h6>{item?.student_assignment_submit_pdf?.split("/")[2]}</h6>
                          <h6 className={`${cx.homework_date}`}>Date-{item?.created_at?.split("T")[0]}</h6>
                          {
                            <a type="button" className="btn" target="_blank" href={`https://appicsoftwares.in/guruCors${item?.student_assignment_submit_pdf}`}><FiDownload /></a>
                          }
                        </span> : <>Not available</>}
                      </div>
                    )
                  })}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <StudentDetails upcomingData={upcomingData} showStudentDetails={showStudentDetails} handleStudentDetailsClose={handleStudentDetailsClose} />
      <TeacherRating feedback={feedback} show={showTeacherRating} handleTeacherRatingClose={handleTeacherRatingClose} />
      <TeacherDemoDetails demoDetails={demoDetails} showDemo={showDemoStudentDetails} handleDemoStudentDetailsClose={handleDemoStudentDetailsClose} />
      <AssignByAdmin assignByAdmin={assignByAdmin} showAssign={showAssignStudentDetails} handleAssignStudentDetailsClose={handleAssignStudentDetailsClose} />
    </>
  );
}