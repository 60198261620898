import React, { useEffect, useState } from "react";
import st from "../../../style.module.scss";
import cx from "./Applied.module.scss";
import { NavLink, useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { MdArrowBackIos } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Student/Footer/Footer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

export default function Applied() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  let localData = JSON.parse(localStorage.getItem("userData")!)
  const location: any = useLocation();

  function tConvert(time: any) {
    time = time?.toString()?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time?.slice(1);
      time[5] = +time[0] < 12 ? 'AM' : 'PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }

  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  // -------------------------------------------- DEMO JOIN API ------------------------------------//
  function joinDemo(demo_id: any, class_url: any) {
    let formdata = new FormData();
    formdata.append('demo_id', demo_id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/join_demo_class`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response?.data?.status == false) {
          window?.open(`//${class_url}`);
        }
        else {
          notify(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  let dateString1 = location?.state?.showData?.start_time[0]?.select_date
  let parts1 = dateString1?.split("-");
  let formattedDate1 = `${parts1[1]}/${parts1[2]}/${parts1[0]}`;

  let dateString2 = location?.state?.showData?.start_time?.[1]?.select_date
  let parts2 = dateString2?.split("-");
  let formattedDate2 = `${parts2?.[1]}/${parts2?.[2]}/${parts2?.[0]}`;

  let dateString3 = location?.state?.showData?.start_time?.[2]?.select_date
  let parts3 = dateString3?.split("-");
  let formattedDate3 = `${parts3?.[1]}/${parts3?.[2]}/${parts3?.[0]}`;

  let joinLink = location?.state?.showData?.zoom_link

  return (
    <>
      <ToastContainer />
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <Row className={`align-items-center ${st.width100}`}>
                <Col className={`col-2`}>
                  <NavLink to="/student/home" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.headTitle}`}>
                  <h6>Applied</h6>
                </Col>
                <Col className={`col-2 text-end`}></Col>
              </Row>
            </div>
          </div>
          <div className={`${st.spaceBox}`}></div>
          <div className={`container ${cx.contentBody} mt-3`}>
            <div className={`${cx.detailsBox}`}>
              <img alt="class image" src={location?.state?.showData?.category_id == 0 ? location?.state?.imageUrl + location?.state?.showData?.subject_image : location?.state?.courseUrl + "/" + location?.state?.showData?.courses_image} />
              <h5>{location?.state?.name}</h5>
              <p>
                <span>Price range : <span>{localData?.currency.slice(4)}{location?.state?.showData?.start_price_range}</span> - <span>{localData?.currency.slice(4)}{location?.state?.showData?.end_price_range}</span></span>
              </p>
              <div className={`${cx.preferenceBox}`}>
                <ul>
                  <h6>Preference 1</h6>
                  <li>
                    <span>Date- {formattedDate1}</span>
                    <span>Time- {tConvert(location?.state?.showData?.start_time[0]?.select_time)}</span>
                  </li>
                </ul>
              </div>
              <div className={`${cx.preferenceBox}`}>
                <ul>
                  <h6>Preference 2</h6>
                  <li>
                    <span>Date- {formattedDate2?.split("/")?.[0] == "undefined" ? "Not selected" : formattedDate2}</span>
                    <span>Time- {tConvert(location?.state?.showData?.start_time[1]?.select_time ? location?.state?.showData?.start_time[1]?.select_time : "Not Selected")}</span>
                  </li>
                </ul>
              </div>
              <div className={`${cx.preferenceBox}`}>
                <ul>
                  <h6>Preference 3</h6>
                  <li>
                    <span>Date- {formattedDate3?.split("/")?.[0] == "undefined" ? "Not selected" : formattedDate3}</span>
                    <span>Time- {tConvert(location?.state?.showData?.start_time[2]?.select_time ? location?.state?.showData?.start_time[2]?.select_time : "Not Selected")}</span>
                  </li>
                </ul>
              </div>
              <div className={`${cx.preferenceSelect}`}>
                <div className={`${cx.preferenceTitle}`}>
                  <h5>Assigned Teacher</h5>
                  <ul>
                    <li>
                      <span>Name - {location?.state?.showData?.teacher_name || "Top Tutor Shortlisted"}</span>
                    </li>
                    <li>Date- {formattedDate1}</li>
                    <li>Time - {tConvert(location?.state?.showData?.teacher_name ? location?.state?.showData?.start_time[0]?.select_time : "")}</li>
                    <li>
                      <button className={`${cx.linkBtn}`}
                        onClick={() => {
                          if (joinLink && joinLink.startsWith('https://')) {
                            joinLink = joinLink.replace('https://', '');
                          }
                          joinDemo(location?.state?.showData?.id, joinLink);
                        }}>
                        Join
                        {/* {demoStatus == false ? window?.open(`//${joinLink}`) : "Join"} */}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className={`pt-3`}></div>
        </div>
      </section>
      <Footer />
    </>
  );
}