import React, { useEffect, useState } from "react";
import st from "../../../style.module.scss";
import cx from "./Wallet.module.scss";
import { NavLink, useHistory } from "react-router-dom";
import { MdArrowBackIos, MdOutlineClose } from "react-icons/md";
import { Col, Row, Nav, Tab } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Student/Footer/Footer";
import axios from 'axios';
import ReactLoading from "react-loading";
import { toast, ToastContainer } from "react-toastify";

export default function Wallet() {

  useEffect(() => {
    window.scrollTo(0, 0);
    classDebitAndCredit();
  }, [])

  let localData = JSON.parse(localStorage.getItem("userData")!);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [credited, setcredited] = useState<any>([]);
  const [debited, setdebited] = useState<any>([]);
  const [freeClass, setfreeClass] = useState<any>("");
  const [filterItem, setFilterItem] = useState<any>([]);
  const [student_apply_for_demo_id, setStudent_apply_for_demo_id] = useState<any>("");
  const [selectedEnrollId, setSelectedEnrollId] = useState(student_apply_for_demo_id?.[0]?.enroll_id);
  const handleSelectChange = (event: any) => {
    setSelectedEnrollId(event.target.value);
  };

  useEffect(() => {
    setStudent_apply_for_demo_id(filterItem)
  }, [filterItem])

  const [loaderStatus, setloaderStatus] = useState(
    <ReactLoading type="cylon" color="blue" />
  );
  const navigate = useHistory();

  // -------------------------------------------- WALLET API ------------------------------------------------//
  function classDebitAndCredit() {

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/craditdebitAvailableClass`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
    };
    axios(config)
      .then(function (response) {
        setcredited(response.data.credited)
        setdebited(response.data.debited)
        setfreeClass(response?.data?.free_class)
        const bothFilter = response.data.both.filter((obj: any, index: any) =>
          response.data.both.findIndex(
            (event: any) =>
              event.teacher_id == obj.teacher_id &&
              event.class_id == obj.class_id &&
              event.category_id == obj.category_id
          ) == index
        );

        console.log(bothFilter, "bothFilterbothFilter")

        const filterItemArr: any = []
        bothFilter.map((filterItem: any) => {
          filterItemArr?.push({
            teacher: filterItem?.teacher_name,
            id: filterItem?.student_apply_for_demo_id,
            class: filterItem?.class_name,
            subject: filterItem?.subject_name,
            enroll_id: filterItem?.enroll_id,
            course_name: filterItem?.course_name,
            category_name: filterItem?.category_name
          });
        })
        setFilterItem(filterItemArr)

        // })
        if (response.data.debited || response.data.credited) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // -------------------------------------------- ENROLL CLASS API ---------------------------------------//
  function enrollClass() {

    let formdata = new FormData();
    formdata.append('demo_id', selectedEnrollId ?? student_apply_for_demo_id?.[0]?.id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/re-enroll-class`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        console.log(response, "oiwghwegw09420909420429420")
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
        if (response.data.statusCode == 200) {
          setTimeout(() => {
            navigate.push({
              pathname: '/student/enroll',
              state: { item: response.data.data[0] }
            });
          }, 1000);
        }
      })
      .catch(function (error) {
        console.log(error)
      });
  }

  // -------------------------------------------- ENROLL CLASS API ---------------------------------------//
  function demoClass() {

    let formdata = new FormData();
    formdata.append('enroll_id', selectedEnrollId ?? student_apply_for_demo_id?.[0]?.enroll_id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/re-demo-class`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        console.log(response, "respspspspspspspps")
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
        if (response.data.statusCode == 200) {
          setTimeout(() => {
            navigate.push({
              pathname: '/student/enroll',
              state: { item: response.data.data[0] }
            });
          }, 1000);
        }
      })
      .catch(function (error) {
        console.log(error)
      });
  }
  return (
    <>
      <ToastContainer limit={1} />
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <Row className={`align-items-center ${st.width100}`}>
                <Col className={`col-2`}>
                  <NavLink to="/student/home" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.headTitle}`}>
                  <h6>Wallet</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
          </div>
          <div className={`${cx.spaceBox}`}></div>

          <div className={`${cx.availableClass}`}>
            <p>Available Credits </p>
            <button className={`${cx.enrollBtn}`}
              onClick={() => {
                handleShow1();
              }}>Enroll</button>
            <div className={`${cx.classCount}`}><span>{credited.length} Classes</span></div>
            <div className={`${cx.classCount}`}><span>{freeClass} Super Credits</span></div>
          </div>

          <Tab.Container id="left-tabs-example" defaultActiveKey="tabClass1">
            <div className={`container mt-4 ${cx.menuList}`}>
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link eventKey="tabClass1">Class Credited</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tabClass2">Class Debited</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className={`container ${cx.contentBody}`}>

              <Tab.Content>
                <Tab.Pane eventKey="tabClass1">
                  <br />
                  <Row>
                    {credited?.length == 0 ? (
                      <div className="d-flex justify-content-center">
                        {loaderStatus}
                      </div>
                    ) :
                      credited?.map((item: any) => {
                        let localTime = new Date(`${item?.select_date} ${item?.start_time} UTC`).toLocaleString("en-US", { timeZone: localData?.timezone });
                        let splitDate = localTime.split(",")
                        return (
                          <Col lg={4}>
                            <NavLink to="#" className={`${cx.referalsHistory}`}>
                              <div className={`${cx.referalsBox}`}>
                                <h6>{item?.teacher_name}</h6>
                                <p>Date - {item?.select_date === "yy-mm-dd" ? "yy-mm-dd" : splitDate[0]}</p>
                              </div>
                              <div className={`${cx.classTime}`}>
                                <p>{item?.class_name ? "Class Name" : "Category Name"} - {item?.class_name ? item?.class_name : item?.category_name}</p>
                                <p>Time - {item?.start_time === "hh-mm" ? "hh-mm" : splitDate[1]}</p>
                              </div>
                              <p>Transaction ID - {item?.transation_id}</p>
                              {/* <button className={cx.enrollBtn}
                                onClick={enrollClass}
                              >Enroll</button> */}
                            </NavLink>
                          </Col>
                        );
                      })}
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="tabClass2">
                  <br />
                  <Row>
                    {debited?.length == 0 ? (
                      <div className="d-flex justify-content-center">
                        {loaderStatus}
                      </div>
                    ) :
                      debited?.map((item: any, index: any) => {
                        let localTime = new Date(`${item?.select_date} ${item?.start_time} UTC`).toLocaleString("en-US", { timeZone: localData?.timezone });
                        let splitDate = localTime.split(",")
                        return (
                          <Col lg={4}>
                            <NavLink to="#" className={`${cx.referalsHistory}`}>
                              <div className={`${cx.referalsBox}`}>
                                <h6>{item?.teacher_name}</h6>
                                <p>Date - {splitDate[0]}</p>
                              </div>
                              <div className={`${cx.classTime}`}>
                                <p>{item?.class_name ? "Class Name" : "Category Name"} - {item?.class_name ? item?.class_name : item?.category_name}</p>
                                <p>Time - {splitDate[1]}</p>
                              </div>
                              <p>Transaction ID - {item?.transation_id}</p>
                              {/* <button className={cx.enrollBtn}
                                // onClick={() => {
                                //   navigate.push("/student/classes", {
                                //     item: item,
                                //   });
                                // }}
                                onClick={enrollClass}
                              >Enroll</button> */}
                            </NavLink>
                          </Col>
                        );
                      })}
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </section >
      <Footer />

      <div className={`${cx.popupBox} ${show1 && cx.popupShow}`}>
        <div className={`${cx.bgBox}`}></div>
        <div className={`${cx.popupBoxBody}`}>
          <div className={`${cx.cotentBody}`}>
            <Row>
              <Col className="col-12">
                <div className={`${cx.homeworkHeading}`}>
                  <h3>Enroll</h3>
                  <i className={`${cx.closeIcon}`} onClick={handleClose1}>
                    <MdOutlineClose />
                  </i>
                </div>
                <Row className={`${cx.taskForm}`}>
                  <Col md={8}>
                    <select
                      onChange={handleSelectChange}
                      className="form-select">
                      {filterItem?.length === 0 ? (
                        <option value="">No options available</option>
                      ) : (
                        <>
                          {filterItem?.map((item: any, index: any) => (
                            <option
                              key={`filterItem-${item.id}`}
                              selected={index === 0}
                              value={item.id == "0" ? item?.enroll_id : item?.id}
                            >
                              {item.teacher} - {item.class ?? item?.category_name} ({item.subject ?? item?.course_name})
                            </option>
                          ))}
                        </>
                      )}
                    </select>

                  </Col>
                  <Col md={4}>
                    <button className={`btn ${cx.submitBtn}`}
                      onClick={() => {
                        if (filterItem?.length == 0) {
                          toast("Class not available", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            autoClose: 3000,
                          });
                        } if (student_apply_for_demo_id?.[0]?.id == 0) {
                          demoClass();
                        } else {
                          enrollClass();
                        }
                      }}>Enroll</button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}