import React from 'react'
import m from "./Modal.module.scss";
import { Modal, Button } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ApprovedSlot = ({ show1, handleClose1, props, particularClass, type }: any) => {
    const notify = (message: String) =>
        toast(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });

    function approvedSlots() {
        let formdata = new FormData();
        formdata.append(`${type == "enroll" ? 'schedule_id' : "demo_id"}`, type == "enroll" ? props?.id : props?.t_id);
        formdata.append("approve_class", "Approve");
        formdata.append("type", type);

        var config = {
            method: "post",
            url: `${process.env.REACT_APP_BASEURL}/ragular_class_approve`,
            headers: {
                Authorization: "Bearer" + localStorage.getItem("api_token"),
            },
            data: formdata,
        };
        axios(config)
            .then(function (response) {
                handleClose1();
                particularClass();
                notify("Session approved successfully.");
            })
            .catch(function (error) {
                console.log(error)
                notify("Something went wrong. Please try again later");
            });
    }
    return (
        <>
            <ToastContainer limit={1} />
            <Modal show={show1} onHide={handleClose1} centered className={`${m.reschedule_popUp}`}>
                <Modal.Header>
                    <Modal.Title>Do you want to approve this session ?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <NavLink to="#" className={`btn border-end ${m.actionBtn}`} onClick={handleClose1}>
                        NO
                    </NavLink>
                    <Button className={`btn ${m.actionBtn}`} onClick={() => {
                        approvedSlots();
                    }}>
                        YES
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ApprovedSlot