import React, { useEffect, useState } from "react";
import st from "../../../style.module.scss";
import cx from "./Otp.module.scss";
import OtpInput from 'react18-input-otp';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg-img-new.svg";
import logo from "../../../images/logo.png";
import { RotatingLines } from "react-loader-spinner";

export default function Otp() {

  //---react-toastify-----//
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);
  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify])

  const [otp, setOtp] = useState("");

  const handleChange = (enteredOtp: any) => {
    setOtp(enteredOtp);
  };

  const location: any = useLocation()
  let id = location?.state?.detail
  // let email = location?.state?.email
  let component = location?.state?.component
  const navigate = useHistory();
  const [boolean, setBoolean] = useState(location?.state?.boolean);
  const [loadingSpinner, setLoadingSpinner] = useState<any>('Verify');
  const [disablebtn, setDisablebtn] = useState(false);
  const [resentBtn, setresentBtn] = useState(false);

  const [fcmToken, setfcmToken] = useState('');

  //-------------------------Otp match--------------------------//
  function otpMatch(e: any) {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append('otp', otp);
    formdata.append('userId', id);
    formdata.append('deviceToken', fcmToken)

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/otpmatch`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    setLoadingSpinner(
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />)
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.replace({
              pathname: '/student/complete-profile',
              state: {
                detail: response.data.class_subject_details[0].classes,
                userId: response.data.data.userId,
                email: response.data.data.email,
                name: response.data.data.name
              }
            });
          }, 1000);
        } else {
          setLoadingSpinner('Verify')
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        notify("Something went wrong, Please try again later");
        console.log(error)
      });
  }

  //-------------------------Forgot password API-------------------------//
  function enterOtp(e: any) {
    setDisablebtn(true);
    e.preventDefault();
    let formdata = new FormData();
    formdata.append('otp', otp);
    formdata.append('userId', id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/verifyOtp`,
      data: formdata,
    };
    axios(config)
      .then(function (response) {

        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/student/password-forgot',
              state: { detail: response.data.data.userId }
            });
          }, 1000);
        } else {
          setDisablebtn(false);
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
      })
      .catch(function (error) {
        setDisablebtn(false);
        setTimenotify(error.response.data.message);
      });
  }

  //------------------------- Otp Resend API -------------------------//
  function resendOtp(e: any) {
    setresentBtn(true);
    e.preventDefault();
    let formdata = new FormData();
    formdata.append('email', location?.state?.email);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/resendotp`,
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        setTimeout(() => {
          setresentBtn(false);
        }, 3000);
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        } else {
          setTimeout(() => {
            setresentBtn(false);
          }, 3000);
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        setTimeout(() => {
          setresentBtn(false);
        }, 3000);
        setTimenotify(error.response.data.message);
      });
  }

  // -------------------------Check forgot password otp-------------------------//
  function forgotpassword(e: any) {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append('userId', location?.state?.detail);
    formdata.append('otp', otp);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/checkForgetPasswordOtp`,
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimeout(response.data.message);
          setBoolean(true)
          setTimeout(() => {
            navigate.push("/student/change-password");
          }, 1000);
        } else {
          setBoolean(true)
        }
      })
      .catch(function (error) {
        notify("Something went wrong");
      });
  }

  return (
    <>
      <ToastContainer limit={1} />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
            </div>
            <img src={bg} className={`${cx.bgShape}`} />
            <Col className="text-center"><img src={logo} className={`${cx.logo}`} />
            </Col>
          </div>

          <div className={`${cx.contentBody} mt-3`}>
            <Col lg={4} className={'m-auto'}>
              <h5 className={`${st.themeColor}`}>Verify</h5>
              <p className={`${st.fontColor}`}>We have sent an OTP to your email- {location?.state?.email}</p>
            </Col>

            <Col lg={4} className={'m-auto'}>
              <Form className={`${st.formFull}`}>
                <Form.Group className={`${st.formField}`} controlId="">
                  <div className={`${cx.otpBox}`}>
                    <OtpInput
                      value={otp}
                      isInputNum={true}
                      className="form-control"
                      onChange={handleChange}
                      numInputs={4}
                      separator={<span></span>}
                    />
                  </div>
                  <p className={`${st.fontColor} text-left mt-4 mb-5`} style={{ fontSize: '15px' }}>Didn't receive the OTP?
                    {/* <NavLink
                      className={`${st.themeColor} text-decoration-none`}
                      onClick={resendOtp} to="#" > Send again
                    </NavLink> */}
                    {resentBtn ? <button
                      disabled
                      onClick={resendOtp}
                      style={{
                        background: 'none',
                        border: 'none',
                        padding: '0',
                        color: 'grey',
                        marginLeft: "5px",
                        textDecoration: 'none',
                        cursor: 'pointer'
                      }}
                    >
                      Send again
                    </button> : <button
                      onClick={resendOtp}
                      style={{
                        background: 'none',
                        border: 'none',
                        padding: '0',
                        color: '#375dbc',
                        marginLeft: "5px",
                        textDecoration: 'none',
                        cursor: 'pointer'
                      }}
                    >
                      Send again
                    </button>}

                  </p>
                </Form.Group>

                <Form.Group className={`${st.formField}`}>
                  <Col lg={12}>
                    {disablebtn ? <button disabled className={`btn ${st.actionBtn}`}
                      onClick={(e) => {
                        component == "signup" ? otpMatch(e) : boolean == true ? forgotpassword(e) : enterOtp(e)
                      }}>
                      {loadingSpinner}
                    </button> :
                      <NavLink to="#" className={`btn ${st.actionBtn}`}
                        onClick={(e) => {
                          component == "signup" ? otpMatch(e) : boolean == true ? forgotpassword(e) : enterOtp(e)
                        }}>
                        {loadingSpinner}
                      </NavLink>}
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </div>
        </div>
      </section>
    </>
  );
}