import React, { useState, useEffect } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Completeprofile.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Row, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg-img-new.svg";
import logo from "../../../images/logo.png";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import Select from 'react-select';
import { RotatingLines } from "react-loader-spinner";

export default function Completeprofile(props: any) {
  const location: any = useLocation();
  let localData = JSON.parse(localStorage.getItem("userData")!);

  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  // function phoneInput(value: any, data: any) {
  //   console.log(data, "renviurbiurbiurvbrvbiur")
  //   setphone(value);
  //   setDialCode(data.dialCode)
  // }

  // ------------------------------------------- COMPLETE PROFILE ------------------------------------------- //
  const navigate = useHistory();
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [parent_name, setparent_name] = useState("");
  const [phone, setphone] = useState("");
  const [class_id, setClass_id] = useState<any>([]);
  const [subject_id, setSubject_id] = useState<any>([]);
  const [category_id, setcategory_id] = useState<any>([])
  const [course_id, setCourse_id] = useState<any>([]);
  const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [abbrev, setAbbrev] = useState('')
  const [currency, setCurrency] = useState("")
  const [student_complete_profile_status] = useState(1);
  const [loadingSpinner, setLoadingSpinner] = useState<any>('Submit');
  const [dialCode, setDialCode] = useState("");

  function phoneInput(value: any, data: any) {
    const dialCode = `+${data.dialCode}`;
    const mobileNumber = value?.substring(data?.dialCode?.length);
    setphone(mobileNumber)
    setDialCode(dialCode)
  }

  // ------------------------------------------- CLASS DETAILS ------------------------------------------- //
  const [subjectorcourses, setsubjectorcourses] = useState<any>([]);
  const [classData, setClassData] = useState<any>([]);

  useEffect(() => {
    getallclscategory();
  }, [])
  let formdata = new FormData();

  // ----------------------------- GET ALL CLASS CATEGORY API------------------------------ //
  function getallclscategory() {

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/get_all_class_subject_categories_course`,
      headers: {
        "Authorization": "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };

    axios(config)
      .then(function (response) {
        // console.log(response, "response")
        if (response.data.statusCode == 200) {
          // GET ALL CATEGORY AND COURSES IN DROPDOWN
          let classcategories: any[] = [];
          let categoriesData: any[] = [];
          response.data.data?.classDetails?.forEach((subelement: any, index: number) => {
            // console.log(subelement, "itemememem")
            classcategories.push({ extraData: subelement?.subjectDetails, label: subelement?.class_name, value: `${subelement?.id + response.data.data.categoryDetails.length}`, uniqueId: subelement?.id, type: 'class' })
          });
          response.data.data?.categoryDetails?.forEach((subelement: any, index: number) => {
            // console.log(subelement, "itemem22emem")
            classcategories.push({ extraData: subelement?.courseDetails, label: subelement?.name, value: `${subelement?.id + response.data.data.categoryDetails.length}`, uniqueId: subelement?.id, type: 'category' })
          });
          // setCategoryDetailsLength(response.data.data.categoryDetails.length)
          setClassData(classcategories)
          // setCategoryData(categoriesData)
          localStorage.setItem("userData", JSON.stringify(response.data.data));
        }
        else {
          setTimenotify(response.data.message);
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
      });
  }

  function handleClassCategoryStatus(eventclass: any) {
    if (eventclass?.type === "class") {

      setClass_id(eventclass.uniqueId);
      setcategory_id("");
      setSubject_id([])
      setCourse_id([])
    } else {

      setcategory_id(eventclass.uniqueId);
      setClass_id("");
      setSubject_id([]);
      setCourse_id([])
    }

    if (eventclass.type == "categories") {
      let extraData: any = []
      // setType("2");
      setsubjectorcourses(extraData)
      eventclass?.extraData.forEach((course: any) => {
        // console.log(course, "cour567890se")
        extraData?.push({ label: course?.name, value: course?.id })
      });
    } else {
      let extraData: any = []
      // setType("1");
      setsubjectorcourses(extraData)
      eventclass?.extraData.forEach((subject: any) => {
        // console.log(subject, "subject567898765")
        extraData?.push({ label: subject?.title || subject?.name, value: subject?.id })
      });
    }
  }
  function handleSubjectCoursesStatus(event: any) {
    // console.log(event, "event")
    if (class_id == "") {
      setCourse_id(event)
      setSubject_id([])
    } else {
      setSubject_id(event)
      setCourse_id([])
    }
    // setSubjectorCourseId(event.value)
  }

  //------------------------------ STUDENT COMPLETE PROFILE API ----------------------------//
  function studentProfile(valueSubject: any) {
    let formdata = new FormData();
    formdata.append('userId', location?.state?.userId);
    formdata.append('email', location?.state?.email);
    formdata.append('name', name);
    formdata.append('parent_name', parent_name);
    formdata.append('phone', phone);
    formdata.append('class_id', class_id);
    formdata.append('subject_id', class_id ? JSON.stringify(valueSubject) : "");
    formdata.append('category_id', category_id);
    formdata.append('course_id', category_id ? JSON.stringify(course_id) : "");
    formdata.append('address', address);
    formdata.append('currency', currency);
    formdata.append('timezone', timezone);
    formdata.append('student_complete_profile_status', student_complete_profile_status.toString());
    formdata.append('abbrev', abbrev);
    formdata.append("dial_code", dialCode);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/studentcompleteProfile`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    setLoadingSpinner(
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />)
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/student/login',
              state: { detail: response.data.id }
            });
          }, 2000);
        }
        else {
          setLoadingSpinner('Submit')
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
      });
  }

  return (
    <>
      <ToastContainer limit={1} />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
              <Row className={`${cx.titleBar}`}>
                <Col>
                </Col>
                <Col>
                </Col>
                <Col className="text-end">
                </Col>
              </Row>
            </div>
            <img src={bg} className={`${cx.bgShape}`} />
            <Col className="text-center"><img src={logo} className={`${cx.logo}`} />
            </Col>
          </div>

          <div className={`${cx.contentBody} mt-3`}>
            <Col lg={4} className={'m-auto'}>
              <h5 className={`${st.themeColor}`}>Complete Profile</h5>
              <p></p>
            </Col>
            <Col lg={4} className={'m-auto'}>
              <Form className={`${st.formFull}`}>
                <Form.Group className={`${st.formField}`} controlId="">
                  <Form.Control type="text" placeholder="Student Name"
                    defaultValue={localData?.name}
                    key={props?.name}
                    onChange={(e) => {
                      setName(e.target.value)
                    }} />
                </Form.Group>

                <Form.Group className={`${st.formField}`} controlId="">
                  <Form.Control type="text" placeholder="Parent Name" defaultValue={localData?.parent_name}
                    key={props?.parent_name}
                    onChange={(e) => {
                      setparent_name(e.target.value)
                    }} />
                </Form.Group>

                <Form.Group className={`${st.formField}`} controlId="">
                  <Form.Control type="text" disabled
                    value={location?.state?.email ?? ""}
                    key={props?.email} />
                </Form.Group>

                <Form.Group className={`${st.formField}`}>
                  <Select key={props?.class_id}
                    onChange={handleClassCategoryStatus}
                    options={classData}
                    placeholder="Select Class/Category"
                  />
                </Form.Group>

                <Form.Group className={`${st.formField}`}>
                  <Select isMulti key={props?.subject_id}
                    onChange={handleSubjectCoursesStatus}
                    value={class_id ? subject_id : course_id}
                    id="subject_id"
                    options={subjectorcourses}
                    placeholder="Select Subject/Course"
                  />
                </Form.Group>

                <Col className="col-12">
                  <div className={`${cx.profileEdit}`}>
                    <PhoneInput
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: true,
                      }}
                      onChange={phoneInput}
                    />
                  </div>
                </Col>

                <Row>
                  <Col className="col-6">
                    <div className={`${cx.profileEdit}`}>
                      {/* <label htmlFor="">Timezone</label> */}
                      <TimezoneSelect
                        value={timezone}
                        // labelStyle="abbrev"
                        onChange={(e: any) => {
                          // console.log(e, "emekayayay")
                          setTimezone(e?.value);
                          setAbbrev(e?.abbrev);
                        }}
                        timezones={{
                          ...allTimezones,
                          'Asia/Kolkata': 'IST (Indian Standard Time)',
                          'Asia/Dubai': 'GST (Gulf Time)',
                          'America/Los_Angeles': 'PST/PDT(USA - Pacific Time)',
                          'America/Detroit': 'EST/EDT(USA - Eastern Time)',
                          'America/Chicago': 'CST/CDT (USA - Central Time)',
                          'America/Boise': 'MST/MDT(USA -  Mountain Time)',
                          'Asia/Kuala_Lumpur': 'SGT(Singapore Time)',
                          'Europe/London': "BST/GMT"
                        }}
                      />
                    </div>
                  </Col>

                  <Col>
                    <Form.Group className={`${st.formField}`} controlId="">
                      <Form.Select aria-label=""
                        key={props?.currency}
                        value={localData?.currency}
                        onChange={(e) => {
                          setCurrency(e.target.value)
                        }}>
                        <option value="">Select Currency</option>
                        <option value="INR-₹">INR- ₹</option>
                        <option value="USD-$">USD- $</option>
                        <option value="AUD-$">AUD- $</option>
                        <option value="CAD-$">CAD- $</option>
                        <option value="EUR-€">EUR- €</option>
                        <option value="NZD-$">NZD- $</option>
                        <option value="GBP-£">GBP- £</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className={`${st.formField}`} controlId="">
                  <Form.Control as="textarea" placeholder="Address" rows={3} style={{ minHeight: '120px' }}
                    defaultValue={localData?.address}
                    key={props?.address}
                    onChange={(e) => {
                      setAddress(e.target.value)
                    }}
                  />
                </Form.Group>

                <Form.Group className={`${st.formField}`}>
                  <Col lg={12}>
                    <Button className={`btn ${st.actionBtn}`}
                      onClick={() => studentProfile(subject_id)}>
                      {loadingSpinner}
                    </Button>
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </div>
        </div>
      </section>
    </>
  );
}