import React from "react";
import m from "./Modal.module.scss";
import { Modal, Row, Col } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import { FaStarHalfAlt } from "react-icons/fa";

const TeacherRating = (props: any) => {
    let { show, handleTeacherRatingClose } = props;
    return (
        <>
            <Modal
                centered
                show={show}
                onHide={handleTeacherRatingClose}
                aria-labelledby="example-modal-sizes-title-sm"
                className={`${m.modalteacherRating}`}>
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Feedback
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   
                    {props?.feedback ? (
                        <div className={`${m.teacherRatingPopup}`}>
                            <Row>
                                <Col md={12}>
                                    <div className={`${m.rating_star}`}>
                                        {Array.from({ length: Math.floor(props?.feedback?.rating) }).map((_, i) => (
                                            <FaStar key={i} />
                                        ))}
                                        {props?.feedback?.rating % 1 !== 0 && <FaStarHalfAlt />}
                                    </div>

                                    <div className={`${m.feedback_content}`}>
                                        {/* Display the feedback message */}
                                        <p>{props?.feedback?.message}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <div>
                            Feedback not available
                        </div>
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <div className={`${m.btnGroup}`}>
                        <button style={{ backgroundColor: "#375dbc", color: "#fff" }} className={`btn ${m.actionBtn}`} onClick={handleTeacherRatingClose}>Close</button>
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    );
};

export default TeacherRating;