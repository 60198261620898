import React, { useState, useEffect } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Notification.module.scss";
import { Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../../../components/Teacher/Footer/Footer";
import Header from "../../../components/Header/Header";
import { NavLink, useHistory } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import ReactLoading from "react-loading";
import { MdArrowBackIos } from "react-icons/md";
import bg from "../../../images/bg1.svg";

export default function Teachernotification() {
  const [data, setData] = useState<any[]>([]);
  const [id, setid] = useState<any>("");

  const [loaderStatus, setloaderStatus] = useState(
    <ReactLoading type="cylon" color="blue" />
  );

  const navigate: any = useHistory();

  useEffect(() => {
    notification()
  }, [])

  useEffect(() => {
    if (id !== "") {
      notificationCount()
    }
  }, [id])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // ----------------------- NOTIFCATION API ----------------------- //
  function notification() {
    let formdata = new FormData();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_BASEURL}/get_teacher_notification`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div>Notification Not Found ...</div>);
        }
        // console.log(response, "response")
        setData(response.data.data);
        let idVal: any = response?.data?.data?.map((item: any) => {
          // console.log(item, "item1123")
          return item?.id
        }).join(",")
        setid(idVal)
      })
      .catch(function (error) {
        console.log(error)
      });
  }

  // ----------------------- NOTIFCATION COUNT API ----------------------- //
  function notificationCount() {
    let formdata = new FormData();
    formdata.append("id", id);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/read_notification_teacher`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.data) {
          setloaderStatus(<div>Notification Not Found ...</div>);
        }
      })
      .catch(function (error) {
        console.log(error)
      });
  }

  return (
    <>
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
              <Row className={`${cx.titleBar}`}>
                <Col className={`col-2`}>
                  <NavLink to="/teacher/home" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.aboutUs}`}>
                  <h6>Notification</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
            <img src={bg} className={`${cx.bgShape}`} />
          </div>
          <div className={`container ${cx.contentBody} mt-3`}>
            <Row>
              {
                data?.length == 0 ? (
                  <div className="d-flex justify-content-center">
                    {loaderStatus}
                  </div>
                ) :
                  data?.map((item: any) => {
                    let newDate: any = new Date(item?.created_at).toLocaleString();
                    return (
                      <Col lg={12}>
                        <div className={`${cx.notificatBox} ${cx.notificatOne}`}>
                          <div className={`${cx.notificatImg}`}>
                          </div>
                          <div className={`${cx.notificationText}`}
                            onClick={() => {
                              if (item?.title == "Home Work") {
                                navigate.push('/teacher/home', {
                                  title: item?.title,
                                  index: 2
                                });
                              } else if (item?.title == 'Demo Cancel Class') {
                                navigate.push('/teacher/home', {
                                  title: item?.title,
                                  index: 0
                                });
                              }
                              else if (item?.title == 'Approve Reschedule Class' || item?.title == 'Enroll Reschedule Class' ||
                                item?.title == "Demo Reschedule Class" || item?.title == "Credits Purchasedt"
                              ) {
                                navigate.push('/teacher/basic-detail', {
                                  title: item?.title,
                                })
                              } else if (item?.title == 'Purchase Creadit') {
                                navigate.push({
                                  pathname: '/teacher/basic-detail',
                                  title: item?.title
                                })
                              }
                              else {
                                navigate.push('/teacher/home', {
                                  title: item?.title,
                                  index: 1
                                });
                              }
                            }}>
                            <div className={`${cx.notificationTitle}`}>
                              <h6>{item?.user_name}</h6>
                              <p>{newDate}</p>
                            </div>
                            <p>{item?.message}</p>
                          </div>
                        </div>
                      </Col>
                    )
                  })}
            </Row>
          </div>
        </div>
      </section >
      <Footer />
    </>
  );
}