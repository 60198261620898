import React, { Suspense, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Aboutus from "./pages/Student/Aboutus/Aboutus";
import Changepassword from "./pages/Student/Changepassword/Changepassword";
import Classdetails from "./pages/Student/Classdetails/Classdetails";
import Classes from "./pages/Student/Classes/Classes";
import Completeprofile from "./pages/Student/Completeprofile/Completeprofile";
import Course from "./pages/Student/Course/Course";
import Error404 from "./pages/Error404/Error404";
import Forgotpassword from "./pages/Student/Forgotpassword/Forgotpassword";
import Home from "./pages/Student/Home/Home";
import Login from "./pages/Student/Login/Login";
import Otp from "./pages/Student/Otp/Otp";
import Signup from "./pages/Student/Signup/Signup";
import Privacypolicy from "./pages/Student/Privacypolicy/Privacypolicy";
import Termsconditions from "./pages/Student/Termsconditions/Termsconditions";
import Setting from "./pages/Student/Setting/Setting";
import Notification from "./pages/Student/Notification/Notification";
import Paymenthistory from "./pages/Student/Paymenthistory/Paymenthistory";
import Referalsoffers from "./pages/Student/Referalsoffers/Referalsoffers";
import Helpsupport from "./pages/Student/Helpsupport/Helpsupport";
import Reschedule from "./pages/Student/Reschedule/Reschedule";
import Wallet from "./pages/Student/Wallet/Wallet";
import Purchaseclass from "./pages/Student/Purchaseclass/Purchaseclass";
import Editprofile from "./pages/Student/Editprofile/Editprofile";
import Calendar from "./pages/Student/Calendar/Calendar";
import Enroll from "./pages/Student/Enroll/Enroll";
import Splash from "./pages/Splash/Splash";
import PlanSeclect from "./pages/Student/PlanSeclect/PlanSeclect";
import Teacherlogin from "./pages/Teacher/Login/Login";
import Teachersignup from "./pages/Teacher/Signup/Signup";
import Teacherforgotpassword from "./pages/Teacher/Forgotpassword/Forgotpassword";
import Teacherotp from "./pages/Teacher/Otp/Otp";
import Teacherchangepassword from "./pages/Teacher/Changepassword/Changepassword";
import Teachercompleteprofile from "./pages/Teacher/Completeprofile/Completeprofile";
import Teacheravailability from "./pages/Teacher/Availability/Availability";
import Teacherhome from "./pages/Teacher/Home/Home";
import Teachernotification from "./pages/Teacher/Notification/Notification";
import Teacherclasses from "./pages/Teacher/Classes/Classes";
import Passwordforgot from "./pages/Student/Passwordforgot/Passwordforgot";
import Applied from "./pages/Student/Applied/Applied";
import Teachercalendar from "./pages/Teacher/Calendar/Calendar";
import TeacherViewmore from "./pages/Teacher/Viewmore/Viewmore";
import Teachersetting from "./pages/Teacher/Setting/Setting";
import Teachereditprofile from "./pages/Teacher/Editprofile/Editprofile";
import Teacheraboutus from "./pages/Teacher/Aboutus/Aboutus";
import Teacherprivacypolicy from "./pages/Teacher/Privacypolicy/Privacypolicy";
import Teachertermsconditions from "./pages/Teacher/Termsconditions/Termsconditions";
import Teachermyearning from "./pages/Teacher/Myearning/Myearning";
import Teacherteachingdetails from "./pages/Teacher/Teachingdetails/Teachingdetails";
import Teacherteachingview from "./pages/Teacher/Teachingview/Teachingview";
import Teacherpasswordforgot from "./pages/Teacher/Passwordforgot/Passwordforgot";
import Teacherapply from "./pages/Teacher/Apply/Apply";
import Teacherinfo from "./pages/Teacher/Info/Info";
import Teacherpayout from "./pages/Teacher/Payout/Payout";
import Teachingadd from "./pages/Teacher/Teachingadd/Teachingadd";
import Categorydetails from "./pages/Student/Categorydetails/CategoryDetails";
import Teacherreschedule from "./pages/Teacher/Reschedule/Reschedule";
import BasicDetail from "./pages/Teacher/NewCardDetail/BasicDetail";
import BasicDetailList from "./pages/Teacher/NewCardDetailList/BasicDetailList";
import Chats from "./pages/Chats/Chats";
import { PublicRoutes, StudentRoutes, TeacherRoutes, CommonRoutes } from "./types/types";
import { Toast } from "react-bootstrap";
import { getDeviceToken, onMessageListener } from "./firebase";

const PrivateRoute = (props: any) => {

  const [fcmToken, setfcmToken] = useState('');
  getDeviceToken().then((token) => {
    setfcmToken(token)
  }).catch((err) => {
    console.error("Error retrieving token:", err);
  });

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState<any>({ title: '', body: '', click_action: '' });

  onMessageListener().then((payload: any) => {
    setNotification({ title: payload.notification.title, body: payload.notification.body, click_action: payload.notification.click_action })
    setShow(true);
  }).catch((err: any) =>
    console.log('failed: ', err));

  const token = localStorage.getItem("api_token");

  return (
    <>
      {
        token ? (
          <>
            <Toast onClose={() => setShow(false)} show={show} delay={3000} style={{
              position: 'absolute',
              top: 80,
              right: 20,
              minWidth: 200,
              backgroundColor: "white",
              zIndex: 999
            }}>
              <Toast.Header style={{
                position: 'relative',
                justifyContent: 'space-between',
              }}>
                <strong className="mr-auto">{notification.title} <a href={notification.click_action}></a></strong>
                <div style={{
                  position: 'absolute',
                  right: '36px',
                  fontSize: "10px",
                  bottom: "11px",
                }}>
                  <small>Just now</small>
                </div>
              </Toast.Header>
              <Toast.Body>{notification.body}</Toast.Body>
            </Toast>
            <Route {...props} />
          </>
        ) : (
          <Redirect
            to={{
              pathname: PublicRoutes.BASE_LOGIN_ROUTE,
            }}
          />
        )
      }
    </>
  );
};

// PRIVATE ROUTES CUSTOM COMPONENT ENDS
const PublicRoute = (props: any) => {
  const token = localStorage.getItem("api_token");
  const data = JSON.parse(localStorage.getItem("userData")!);
  let pathname: string = "";
  if (data?.role_id === 2) {
    pathname = TeacherRoutes.TEACHER_HOME;
  } else if (data?.role_id === 6) {
    pathname = StudentRoutes.STUDENT_HOME;
  } else {
    if (token) {
      localStorage.removeItem("api_token");
      localStorage.removeItem("userData");
      pathname = PublicRoutes.BASE_LOGIN_ROUTE;
    }
  }
  return token ? (
    <Redirect to={{ pathname: pathname }} />
  ) : (
    <Route {...props} />
  );

};

function App() {

  return (
    <>
      <div >
        <Suspense fallback={<div className="centered">Loading....</div>}>
          <Switch>
            <PublicRoute path={PublicRoutes.BASE_LOGIN_ROUTE} exact>
              <Splash />
            </PublicRoute>

            {/* Student Routes Starts */}
            <PublicRoute path={StudentRoutes.STUDENT_LOGIN} exact>
              <Login />
            </PublicRoute>
            <PublicRoute path={PublicRoutes.BASE_ERROR_ROUTE} exact>
              <Error404 />
            </PublicRoute>
            <PublicRoute path={StudentRoutes.STUDENT_SIGNUP} exact>
              <Signup />
            </PublicRoute>
            <PublicRoute path={StudentRoutes.STUDENT_SIGNUPID} exact>
              <Signup />
            </PublicRoute>
            <PublicRoute path={StudentRoutes.STUDENT_FORGOT_PASSWORD} exact>
              <Forgotpassword />
            </PublicRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_CHANGE_PASSWORD_HOME} exact>
              <Changepassword />
            </PrivateRoute>
            <PublicRoute path={StudentRoutes.STUDENT_CHANGE_PASSWORD} exact>
              <Changepassword />
            </PublicRoute>
            <PublicRoute path={StudentRoutes.STUDENT_PASSWORD_FORGOT} exact>
              <Passwordforgot />
            </PublicRoute>
            <PublicRoute path={StudentRoutes.STUDENT_COMPLETE_PROFILE} exact>
              <Completeprofile />
            </PublicRoute>
            <PublicRoute path={StudentRoutes.STUDENT_OTP_VERIFICATION} exact>
              <Otp />
            </PublicRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_HOME} exact>
              <Home />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_CLASS_DETAILS} exact>
              <Classdetails />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_APPLIED} exact>
              <Applied />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_COURSE} exact>
              <Course />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_CLASSES} exact>
              <Classes />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_CALENDAR} exact>
              <Calendar />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_PROFILE} exact>
              <Setting />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_ABOUT_US} exact>
              <Aboutus />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_PRIVACY_POLICY} exact>
              <Privacypolicy />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_TERMS_AND_CONDITION} exact>
              <Termsconditions />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_HELP_AND_SUPPORT} exact>
              <Helpsupport />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_NOTIFICATION} exact>
              <Notification />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_PAYMENT_HISTORY} exact>
              <Paymenthistory />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_REFERRAL_REWARDS} exact>
              <Referalsoffers />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_RESCHEDULE} exact>
              <Reschedule />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_ENROLL} exact>
              <Enroll />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_WALLET} exact>
              <Wallet />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_PURCHASE_CLASS} exact>
              <Purchaseclass />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_EDIT_PROFILE} exact>
              <Editprofile />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_CATEGORY_DETAILS} exact>
              <Categorydetails />
            </PrivateRoute>
            <PrivateRoute path={StudentRoutes.STUDENT_PLAN_SELECT} exact>
              <PlanSeclect />
            </PrivateRoute>
            {/* Student Routes Ends */}

            {/* Common Routes */}
            <PrivateRoute path={CommonRoutes.STUDENT_CHATS} exact>
              <Chats />
            </PrivateRoute>

            <PrivateRoute path={CommonRoutes.TEACHER_CHATS} exact>
              <Chats />
            </PrivateRoute>
            {/* Common Routes Ends */}


            {/* Teacher Routes Starts */}
            <PublicRoute path={TeacherRoutes.TEACHER_LOGIN} exact>
              <Teacherlogin />
            </PublicRoute>
            <PublicRoute path={TeacherRoutes.TEACHER_SIGNUP} exact>
              <Teachersignup />
            </PublicRoute>
            <PublicRoute path={TeacherRoutes.TEACHER_FORGOT_PASSWORD} exact>
              <Teacherforgotpassword />
            </PublicRoute>
            <PublicRoute path={TeacherRoutes.TEACHER_OTP_VERIFICATION} exact>
              <Teacherotp />
            </PublicRoute>
            <PublicRoute path={TeacherRoutes.TEACHER_CHANGE_PASSWORD} exact>
              <Teacherchangepassword />
            </PublicRoute>
            <PrivateRoute path={TeacherRoutes.TEACHER_CHANGE_PASSWORD_HOME} exact>
              <Teacherchangepassword />
            </PrivateRoute>
            <PublicRoute path={TeacherRoutes.TEACHER_COMPLETE_PROFILE} exact>
              <Teachercompleteprofile />
            </PublicRoute>
            <PublicRoute path={TeacherRoutes.TEACHER_AVAILABILITY} exact>
              <Teacheravailability />
            </PublicRoute>
            <PrivateRoute path={TeacherRoutes.TEACHER_RESCHEDULE} exact>
              <Teacherreschedule />
            </PrivateRoute>
            <PrivateRoute path={TeacherRoutes.TEACHER_BASIC_DETAILS} exact>
              <BasicDetail />
            </PrivateRoute>
            <PrivateRoute path={TeacherRoutes.TEACHER_BASIC_DETAILS_LIST} exact>
              <BasicDetailList />
            </PrivateRoute>
            <PrivateRoute path={TeacherRoutes.TEACHER_PROPTECTED_AVAILABILITY} exact>
              <Teacheravailability />
            </PrivateRoute>
            <PrivateRoute path={TeacherRoutes.TEACHER_HOME} exact>
              <Teacherhome />
            </PrivateRoute>
            <PrivateRoute path={TeacherRoutes.TEACHER_CLASSES} exact>
              <Teacherclasses />
            </PrivateRoute>
            <PrivateRoute path={TeacherRoutes.TEACHER_VIEW_MORE} exact>
              <TeacherViewmore />
            </PrivateRoute>
            <PrivateRoute path={TeacherRoutes.TEACHER_CALENDAR} exact>
              <Teachercalendar />
            </PrivateRoute>
            <PrivateRoute path={TeacherRoutes.TEACHER_PROFILE} exact>
              <Teachersetting />
            </PrivateRoute>
            <PrivateRoute path={TeacherRoutes.TEACHER_EDIT_PROFILE} exact>
              <Teachereditprofile />
            </PrivateRoute>
            <PrivateRoute path={TeacherRoutes.TEACHER_NOTIFICATION} exact>
              <Teachernotification />
            </PrivateRoute>
            <PrivateRoute path={TeacherRoutes.TEACHER_ABOUT_US} exact>
              <Teacheraboutus />
            </PrivateRoute>
            <PrivateRoute path={TeacherRoutes.TEACHER_PRIVACY_POLICY} exact>
              <Teacherprivacypolicy />
            </PrivateRoute>
            <PrivateRoute path={TeacherRoutes.TEACHER_TERMS_AND_CONDITIONS} exact>
              <Teachertermsconditions />
            </PrivateRoute>
            <PrivateRoute path={TeacherRoutes.TEACHER_MYEARNING} exact>
              <Teachermyearning />
            </PrivateRoute>
            <PrivateRoute path={TeacherRoutes.TEACHER_TEACHING_DETAILS} exact>
              <Teacherteachingdetails />
            </PrivateRoute>
            <Route path={TeacherRoutes.TEACHER_TEACHING_VIEW} exact>
              <Teacherteachingview />
            </Route>
            <Route path={TeacherRoutes.TEACHER_PASSWORD_FORGOT} exact>
              <Teacherpasswordforgot />
            </Route>
            <PrivateRoute path={TeacherRoutes.TEACHER_APPLY} exact>
              <Teacherapply />
            </PrivateRoute>
            <PublicRoute path={TeacherRoutes.TEACHER_INFO} exact>
              <Teacherinfo />
            </PublicRoute>
            <PrivateRoute path={TeacherRoutes.TEACHER_PAYOUT} exact>
              <Teacherpayout />
            </PrivateRoute>
            <PrivateRoute path={TeacherRoutes.TEACHER_ADD} exact>
              <Teachingadd />
            </PrivateRoute>
            {/* <PrivateRoute path={commonRoutes.TEACHER_CHAT_LIST} exact>
              <TeacherChatList />
            </PrivateRoute> */}
          </Switch>
        </Suspense>
      </div>
    </>
  );
}

export default App;