import React, { useState, useEffect } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Reschedule.module.scss";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { MdArrowBackIos } from "react-icons/md";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomDatePicker from '../../../components/Student/Datepicker/ReschduleDatePicker';
import Header from "../../../components/Header/Header";
import { format } from "date-fns";
import Footer from "../../../components/Student/Footer/Footer";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import "moment/locale/es";
import { RotatingLines } from "react-loader-spinner";

export default function Reschedule() {
  let localData = JSON.parse(localStorage.getItem("userData")!);
  const navigate = useHistory();

  const [date, setDate] = useState<any>('');
  // const [saveData, setSaveData] = useState("");
  const [loaderStatus, setloaderStatus] = useState(
    <ReactLoading type="cylon" color="blue" />
  );

  const selectedDay = (val: any) => {
    let date = format(val, "yyyy-MM-dd")
    studentSlotBook(date, val.getDay())
    check_class_start();
    setDate(date);
  };
  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);
  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 6000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const location: any = useLocation();

  const [data, setData] = useState<any>([]);
  const [checkedData, setCheckedData] = useState<any>([]);
  const [get_TeacherAvailabiltyOfSlots, setGet_TeacherAvailabiltyOfSlots] = useState<any>([]);
  const [active, setActive] = useState(0);
  const [start_time, setStart_time] = useState('');
  const [end_time, setEnd_time] = useState('');
  const [allData, setAllData] = useState<any>([])
  const [utc_time, setUtc_Time] = useState('');
  const [time24hr, setTime24hr] = useState("");
  const [loadingSpinner, setLoadingSpinner] = useState<any>('Submit');
  const [checkClass, setCheckClass] = useState()


  const convertTo24HourFormat = (time12hr: any) => {
    const date = new Date('2000-01-01 ' + time12hr);
    const hours24 = date.getHours();

    const formattedTime24hr =
      hours24.toString().padStart(2, '0') +
      ':' +
      date.getMinutes().toString().padStart(2, '0') +
      ':' +
      date.getSeconds().toString().padStart(2, '0');

    return formattedTime24hr;
  };

  useEffect(() => {
    const convertedTime = convertTo24HourFormat(utc_time);
    setTime24hr(convertedTime);
  }, [utc_time]);


  // CONVERT LOCAL TO UTC DATE AND TIME
  const localDateTime = `${date} ${time24hr}`
  const utcDateTime = moment.tz(localDateTime, 'YYYY-MM-DD HH:mm:ss', localData?.timezone).utc().format();

  useEffect(() => {
    setGet_TeacherAvailabiltyOfSlots(allData);
  }, [allData])

  // --------------------------------RESCEDULE DEMO CLASS API (UPCOMMING CLASSES)-----------------------------------//
  function rescheduleUpcommingcls() {
    let formdata = new FormData();
    formdata.append('select_date', utcDateTime?.split("T")?.[0]);
    formdata.append('start_time', start_time);
    formdata.append('utc_date_time', utcDateTime);
    formdata.append('end_time', end_time);
    formdata.append('teacher_availabilty_of_slots_details_id', location?.state?.details?.teacher_availabilty_of_slots_details_id || location?.state?.teacher_availabilty_of_slots_details_id);
    formdata.append('student_enroll_schedule_id', location?.state?.details?.student_enroll_schedule_id || location?.state?.student_enroll_schedule_id);
    formdata.append('teacher_id', location?.state?.teachersId);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/enroll_rescheduleclass`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    setLoadingSpinner(
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />)
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/student/classes',
            });
          }, 3000);
        }
        else {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        }
        setData(response.data.data);
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
      })
      .catch(function (error) {
        notify("Something went wrong, Please try again later");
        console.log(error)
      });
  }

  // --------------------------------RESCEDULE DEMO CLASS API (DEMO CLASSES)-----------------------------------//
  function rescheduleDemocls() {
    let formdata = new FormData();
    formdata.append('select_date', utcDateTime?.split("T")?.[0]);
    formdata.append('start_time', start_time);
    formdata.append('end_time', end_time);
    formdata.append('utc_select_date', utcDateTime)
    formdata.append('student_booking_id', location?.state?.student_booking_id);
    formdata.append('teacher_id', location?.state?.teacher_id);
    formdata.append('utc_time', start_time);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/demo_rescheduleclass`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    setLoadingSpinner(
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />)
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/student/classes',
            });
          }, 3000);
        }
        else {
          setLoadingSpinner('Submit')
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
        setData(response.data.data);
        if (response.data.data) {
          setloaderStatus(<div>Data Not Found ...</div>);
        }
      })
      .catch(function (error) {
        notify("Something went wrong, Please try again later");
        console.log(error)
      });
  }

  // --------------------------------STUDENT BOOK SLOT-----------------------------------//
  function studentSlotBook(date: any, dayNumber: number) {
    let dataArr = data.filter((e: any) => e.select_date != "")
    let formdata = new FormData();
    formdata.append('date', date);
    formdata.append('teacher_id', location?.state?.teachersId || location?.state?.teacher_id);
    formdata.append('data', JSON.stringify(dataArr));

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/book_Slot_student`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        let localdate = '';
        setAllData([])
        const bookedData: any[] = response.data?.data
        let dataArr: any[] = []
        bookedData?.map((item: any) => {
          let localTime = new Date(`${item?.select_date} ${item?.utc_select_time} UTC`).toLocaleString("en-US", { timeZone: localData?.timezone });
          let dateAndTimeArr = localTime?.split(",")
          let dateCheck = dateAndTimeArr[0]?.toString().split("/")
          let dateEndTime = dateAndTimeArr[1]?.toString().split(":")
          dataArr.push({
            day: item?.day,
            id: item?.id,
            select_date: `${dateCheck[2]}-${+dateCheck[0] < 10 ? `0${dateCheck[0]}` : dateCheck[0]}-${+dateCheck[1] < 10 ? `0${dateCheck[1]}` : dateCheck[1]}`,
            studentapplyfordemo_id: item?.studentapplyfordemo_id,
            teacher_assign_id: item?.teacher_assign_id,
            local_select_end_time: `${+dateEndTime[0] + 1}:${dateEndTime[1]}:${dateEndTime[2]}`,
            local_select_time: dateAndTimeArr[1],
            utcStart: item?.utc_select_time,
            utcEnd: item?.utc_select_end_time,
            start_time: item?.start_time
          })
        })
        localdate = date;
        let checkDate = localdate?.split("-")
        let availability: any[] = []
        response.data?.get_TeacherAvailabiltyOfSlots?.map((item: any, index: number) => {
          let num = dayNumber - 1
          num = num == -1 ? 6 : num
          let plus = dayNumber + 1
          plus = plus == 7 ? 0 : plus
          if (dayNumber === index || plus === index || num === index) {
            let check = plus === index ? 1 : num === index ? -1 : 0
            let dateAgain = +checkDate[2] + check
            let dateOnce = `${checkDate[0]}-${+checkDate[1]}-${dateAgain < 10 ? `0${dateAgain}` : dateAgain}`;
            item?.teacher_availabilty_of_slots_details?.map((e: any) => {
              e?.utctime?.map((y: any) => {
                if (y?.start_time != null) {
                  let isoDateString = `${dateOnce}T${y?.start_time}Z`; // 'Z' indicates UTC
                  let localTime = new Date(isoDateString).toLocaleString("en-US", { timeZone: localData?.timezone });
                  let dateAndTimeArr = localTime.split(",");
                  let dateCheck = dateAndTimeArr[0].toString().split("/");
                  let dateEndTime = dateAndTimeArr[1].toString().split(":");
                  availability.push({
                    day: index,
                    teacher_availabilty_of_slots_id: e?.teacher_availabilty_of_slots_id,
                    select_date: `${dateCheck[2]}-${+dateCheck[0] < 10 ? `0${dateCheck[0]}` : dateCheck[0]}-${+dateCheck[1] < 10 ? `0${dateCheck[1]}` : dateCheck[1]}`,
                    teacher_id: e?.teacher_id,
                    local_select_end_time: `${+dateEndTime[0] + 1}:${dateEndTime[1]}:${dateEndTime[2]}`,
                    local_select_time: dateAndTimeArr[1],
                    zoom_link: e?.zoom_link,
                    delete_time_status: e?.delete_time_status,
                    request_status: e?.request_status,
                    reschedule_status: e?.reschedule_status,
                    uid: e?.uid,
                    checked: "",
                    utcStart: y?.start_time,
                    utcEnd: y?.end_time,
                    start_time: dateAndTimeArr[1]
                  })
                }
              })

            })
          }
        })
        let filterDataArr = dataArr?.filter((e: any) => {
          return localdate === e?.select_date
        })
        // setBookedData(filterDataArr)
        let filterAvailabilityArr = availability.filter((e: any) => {
          return localdate === e?.select_date
        })
        setDate(localdate)

        let da: any[] = []

        filterAvailabilityArr.map((e: any, index: any) => {
          let count = 0
          filterDataArr?.map((a: any) => {
            if (a?.utcStart === e?.utcStart) {
              count = count + 1
            }
          })
          if (count === 0) {
            da.push(e)
          }
        })
        setAllData(da)
        if (response.data.data) {
          setloaderStatus(<div>Time Not Available ...</div>);
        }
      })
      .catch(function (error) {
      });
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // -------------------------------------------- CHECK CLASS START API -----------------------------------------//
  function check_class_start() {

    let formdata = new FormData();
    formdata.append('type', location?.state?.type);
    formdata.append(location?.state?.studentapplyfordemo_id ? "demo_id" : "schedule_id",
      location?.state?.studentapplyfordemo_id ?? location?.state?.student_enroll_schedule_id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/check_class_start`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 400) {
          setTimenotify(response.data.message);
          setCheckClass(response?.data?.status)
          setOnetimenotify(true);
        } else {
          setTimenotify(response.data.message);
          setCheckClass(response?.data?.status)
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
        console.log(error)
      });
  }

  return (
    <>
      <ToastContainer limit={1} />
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <Row className={`align-items-center ${st.width100}`}>
                <Col className={`col-2`}>
                  <NavLink to="/student/classes" className={`${cx.back}`}>
                    <MdArrowBackIos />
                  </NavLink>
                </Col>
                <Col className={`col-8 ${cx.headTitle}`}>
                  <h6>Reschedule</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
          </div>
          <div className={`${st.spaceBox}`}></div>

          <div className={`${cx.contentBody} mt-3`}>
            <ul className={`${cx.rescheduleContent}`}>
              <li>
                <h6>{location?.state?.teacher_name ? location?.state?.teacher_name : location?.state?.details?.teacher_name} </h6>
                {/* <p className={`${cx.classPrice}`}>Class Rate - {location?.state?.class_rate || location?.state?.details?.class_fees}{localData?.currency}</p> */}
              </li>
              <li>
                <p>{location?.state?.details?.subject_name || location?.state?.subject_name ? "Subject" : "Course"} - {location?.state?.details?.subject_name || location?.state?.subject_name || location?.state?.details?.course_name}</p>
                <p>{location?.state?.details?.class_name || location?.state?.class_name ? "Class" : "Category"} - {location?.state?.details?.class_name || location?.state?.class_name || location?.state?.details?.category_name}</p>
              </li>
            </ul>
            <CustomDatePicker getSelectedDay={selectedDay} labelFormat={"MMMM"} color={"#374e8c"} />

            <div className={`${cx.timeSlot}`}>
              <br />
              <h6 className={`${cx.timeSlot}`}>Select Time:</h6>
              <ul className={`${cx.timeSlot}`}>
                {data?.length == 0 && get_TeacherAvailabiltyOfSlots?.length == 0 && (
                  <div className="d-flex justify-content-center">
                    {loaderStatus}
                  </div>
                )}
                {get_TeacherAvailabiltyOfSlots?.map((item: any, index: any) => {
                  let localTime = new Date(`${item?.select_date} ${item?.utcStart} UTC`).toLocaleString("en-US", { timeZone: localData?.timezone });
                  let localTime1 = new Date(`${item?.select_date} ${item?.start_time} UTC`).toLocaleString("en-US", { timeZone: localData?.timezone });
                  let splitDateTime = localTime.split(",");
                  let splitDate = splitDateTime[0]?.split('/')
                  // let combineDates = splitDate[2] + "-" + 0 + splitDate[0] + "-" + splitDate[1]
                  return (
                    <>
                      <li>
                        <label className={`${cx.labelCheckbox}`} htmlFor={`id${index}`}>
                          <input type="checkbox" id={`id${index}`} checked={checkedData?.length > 0 ? checkedData?.some((it: any) => it?.utcStart === item?.utcStart && it?.select_date === item?.select_date) : false} value={item?.local_select_time} onChange={(e: any) => {
                            var dt = moment(e.target.value, ["hh:mm:ss A"]).format("HH:mm");
                            // const datechangebasedontime = mtimezone.tz(`${date} ${dt}`, localData?.timezone).utc().format();
                            setUtc_Time(splitDateTime[1])
                            // setutc_date(combineDates)
                            setStart_time(item?.utcStart)
                            setEnd_time(item?.utcEnd)
                            setDate(item?.select_date)
                            setActive(active + 1)
                            if (checkedData?.some((a: any) => a?.utcStart === item?.utcStart && a?.select_date === item?.select_date)) {
                              checkedData.map((b: any, num: any) => {
                                if (b?.utcStart === item?.utcStart && b?.select_date === item?.select_date) {
                                  checkedData.splice(num, 1)
                                }
                              })
                            } else {
                              setCheckedData((prev: any) => {
                                return [item]
                              })
                            }
                          }} />
                          {item?.start_time ? <span className={`${cx.checkmark}`}>{item?.start_time}</span> : ""}
                        </label>
                      </li>
                    </>
                  )
                })}
              </ul>
            </div>

            < div className={`${cx.timeSlot}`}>
              <Col className="col-12">
                {checkClass == 400 ?
                  <button className={`btn ${cx.continue}`} disabled>Submit</button>
                  : <button className={`btn ${cx.continue}`}
                    onClick={() => {
                      let totalTime: any = []
                      allData?.map((item: any) => {
                        if (item.start_time != "")
                          totalTime.push(item?.start_time)
                        // setStartTime(item?.start_time)
                      })
                      if (active == 0) {
                        notify("Please select Time first")
                      } else {
                        { location?.state?.student_booking_id == undefined ? rescheduleUpcommingcls() : rescheduleDemocls() };
                      }
                    }}>{loadingSpinner}
                  </button>}
              </Col>
            </div>
          </div>
          <div className={`pt-3`}></div>
        </div>
      </section >
      <Footer />
    </>
  );
}