import React, { useState, useEffect } from "react";
import st from "../../../style.module.scss";
import cx from "./Setting.module.scss";
import { NavLink, useHistory } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg-img-new.svg";
import edit from "../../../images/edit-img.svg";
import user from "../../../images/userLogo.jpeg";
import arrow from "../../../images/arrow.svg";
import Bottombar from "../../../components/Teacher/Bottombar/Bottombar";
import Footer from "../../../components/Teacher/Footer/Footer";
import Header from "../../../components/Header/Header";
import { AiFillStar } from "react-icons/ai";
import ProfileAvatar from "../../../Custom_Components/ProfileAvatar/ProfileAvatar";

export default function Teachersetting() {

  let localData = JSON.parse(localStorage.getItem("userData")!);
  let teacher_rating = JSON?.parse(localStorage?.getItem("teacher_rating")!);
  let token = localStorage.getItem("api_token")!
  const history = useHistory();

  useEffect(() => {
    if (token) {
      return;
    } else {
      history.push("/");
    }
  }, [])

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
              <Row className={`${cx.titleBar}`}>
                <Col className={`col-2`}>
                </Col>
                <Col className={`col-8 ${cx.aboutUs}`}>
                  <h6>Setting</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
            <img alt="background" src={bg} className={`${cx.bgShape}`} />
          </div>

          <div className={`container ${cx.contentBody} mt-3`}>
            <Form className={`${cx.paddingLeft}`}>
              <Row>
                <Col className={`${cx.profileText}`}>
                  <NavLink to="/teacher/edit-profile" className={`${cx.userView}`}>
                    {/* <img alt="profile" src={localData?.image || user} className={`${cx.userImg}`} /> */}
                    {localData?.image == "" || localData?.image == null ? <ProfileAvatar name={localData?.name} /> : <img alt="profile" src={localData?.image} className={`${cx.userImg}`} />}
                    <i className={`${cx.editIcon}`}><img alt="profile" src={edit} className={`${cx.editImg}`} /></i>
                  </NavLink>
                  <div className={`${cx.userText}`}>
                    <h5>{localData?.name}</h5>
                    <h6><AiFillStar />{teacher_rating || 0}</h6>
                    <p>{localData?.email}</p>
                  </div>
                </Col>
              </Row>
            </Form>
            <Row className={`${cx.bottomPadding}`}>
              <Col lg={6} className={`${cx.paddingBottom}`}>
                <NavLink to="/teacher/my-earning" className={`${cx.nextPage}`}>
                  <h6>My Earning</h6>
                  <img alt='earnings' src={arrow} className={`${cx.arrowImg}`} />
                </NavLink>
              </Col>
              <Col lg={6} className={`${cx.paddingBottom}`}>
                <NavLink to="/teacher/teaching-details" className={`${cx.nextPage}`}>
                  <h6>Teaching Details</h6>
                  <img alt='details' src={arrow} className={`${cx.arrowImg}`} />
                </NavLink>
              </Col>
              <Col lg={6} className={`${cx.paddingBottom}`}>
                <NavLink to="/teacher/pavailability" className={`${cx.nextPage}`}>
                  <h6>Mark Your availabilty</h6>
                  <img alt='availabilty' src={arrow} className={`${cx.arrowImg}`} />
                </NavLink>
              </Col>
              <Col lg={6} className={`${cx.paddingBottom}`}>
                <NavLink to="/teacher/change-your-password" className={`${cx.nextPage}`}>
                  <h6>Change Password</h6>
                  <img alt='Password' src={arrow} className={`${cx.arrowImg}`} />
                </NavLink>
              </Col>
              <Col lg={6} className={`${cx.paddingBottom}`}>
                <NavLink to="/teacher/about-us" className={` ${cx.nextPage}`}>
                  <h6>About Us</h6>
                  <img alt='About' src={arrow} className={`${cx.arrowImg}`} />
                </NavLink>
              </Col>
              <Col lg={6} className={`${cx.paddingBottom}`}>
                <NavLink to="/teacher/privacy-policy" className={`${cx.nextPage}`}>
                  <h6>Privacy Policy</h6>
                  <img alt='Policy' src={arrow} className={`${cx.arrowImg}`} />
                </NavLink>
              </Col>
              <Col lg={6} className={`${cx.paddingBottom}`}>
                <NavLink to="/teacher/terms-and-conditions" className={`${cx.nextPage}`}>
                  <h6>Terms and conditions</h6>
                  <img alt='Terms' src={arrow} className={`${cx.arrowImg}`} />
                </NavLink>
              </Col>
              <Col lg={6} className={`${cx.paddingBottom}`}>
                <NavLink to="#" onClick={handleShow} className={`${cx.nextPage}`}>
                  <h6>Logout</h6>
                  <img alt='Logout' src={arrow} className={`${cx.arrowImg}`} />
                </NavLink>
              </Col>
            </Row>
          </div>
          <div className={`pt-3 ${st.spaceBox}`}></div>
          <Bottombar />
        </div>
      </section>
      <Footer />

      <div
        className={`${cx.popupBox} ${show && cx.popupShow}`}>
        <div className={`${cx.bgBox}`}></div>
        <div className={`${cx.popupBoxBody}`}>
          <div className={`${cx.cotentBody}`}>
            <Row>
              <Col className="col-12">
                <h5>Do you want to logout?</h5>
              </Col>
              <Col className="col-12">
                <NavLink to="#" className={`btn border-end ${cx.actionBtn}`} onClick={handleClose}>
                  NO
                </NavLink>
                <NavLink to="/" className={`btn ${cx.actionBtn}`}
                  onClick={() => {
                    localStorage.removeItem("api_token");
                    localStorage.removeItem("userData");
                  }}>
                  YES
                </NavLink>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}