import React from "react";
import m from "./Modal.module.scss";
import { Modal, Row, Col } from "react-bootstrap";
import ProfileAvatar from "../../../Custom_Components/ProfileAvatar/ProfileAvatar";

const AssignByAdmin = (props: any) => {
    let { showAssign, handleAssignStudentDetailsClose } = props;
    return (
        <>
            <Modal
                centered
                show={showAssign}
                onHide={handleAssignStudentDetailsClose}
                aria-labelledby="example-modal-sizes-title-lg"
                size="lg"
                className={`${m.modalCts}`}>
                <Modal.Body>
                    <div className={`${m.student_detailsPopup}`}>
                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <div className={`${m.imgbox}`}>
                                        {props?.assignByAdmin?.profile_img == null || props?.assignByAdmin?.profile_img == "" ?
                                            <ProfileAvatar name={props?.assignByAdmin?.student_name} />
                                            : <img className={`${m.imgClass}`} src={props?.assignByAdmin?.profile_img} alt={props?.assignByAdmin?.student_name} />}
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Name:</label>
                                    <div className="form-control">{props?.assignByAdmin?.student_name}</div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Curriculum:</label>
                                    <div className="form-control">{props?.assignByAdmin?.curriculum || 'NA'}</div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3">
                                    <label>Query:</label>
                                    <div className="form-control">{props?.assignByAdmin?.query_no || 'NA'}</div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3">
                                    <label>Current Grade:</label>
                                    <div className="form-control">{props?.assignByAdmin?.class_name}</div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3">
                                    <label>Time Zone:</label>
                                    <div className="form-control">{props?.assignByAdmin?.timezone}</div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Country:</label>
                                    <div className="form-control">{props?.assignByAdmin?.country}</div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Preferred Timing:</label>
                                    <div className="form-control">{props?.assignByAdmin?.student_preference?.[0]?.preferred_time || "NA"}</div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={`${m.btnGroup}`}>
                        <button style={{ backgroundColor: "#375dbc", color: "#fff" }}
                            className={`btn ${m.actionBtn}`}
                            onClick={handleAssignStudentDetailsClose}>Close</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AssignByAdmin;