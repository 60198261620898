import React, { useState, useEffect } from "react";
import axios from 'axios';
import st from "../../../style.module.scss";
import cx from "./Calendar.module.scss";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomDatePicker from '../../../components/Student/Datepicker/CustomDatePicker';
import Bottombar from "../../../components/Student/Bottombar/Bottombar";
import { format } from "date-fns";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Student/Footer/Footer";
import ReactLoading from "react-loading";

export default function Calendar() {
  const [date, setDate] = useState('');
  const selectedDay = (val: any) => {
    let date: any = format(val, "yyyy-MM-dd")
    setDate(date);
    studentCalendar(date)
    setloaderStatus(<ReactLoading type="cylon" color="blue" />)
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [getEnroll, setGetEnroll] = useState<any[]>([]);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 1000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const [loaderStatus, setloaderStatus] = useState(
    <ReactLoading type="cylon" color="blue" />
  );
  let localData = JSON.parse(localStorage.getItem("userData")!);

  // ----------------------- STUDENT CALENDAR API ----------------------- //
  function studentCalendar(date: any) {
    let formdata = new FormData();
    formdata.append('select_date', date);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/student_calendar`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        setData(response.data.data);
        setGetEnroll(response.data.getEnroll)
        if (response.data.data) {
          setloaderStatus(<div>Class Not Found ...</div>);
        }
        if (response.data.getEnroll) {
          setloaderStatus(<div>Class Not Found ...</div>);
        }
      })
      .catch(function (error) {
        toast.error("Something went wrong, Please refresh the page");
      });
  }

  // -------------------------------------------- UPCOMMING JOIN API ------------------------------------//
  function joinUpcomming(upcommingENrollID: any, zoom_link: any) {
    let formdata = new FormData();
    formdata.append('schedule_id', upcommingENrollID);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/join_enroll_class`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.status === false) {
          window.open(`//${zoom_link}`, '_blank');
        }
        else {
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        console.log(error)
      });
  }

  // -------------------------------------------- DEMO JOIN API ------------------------------------//
  function joinDemo(demo_id: any, zoom_link: any) {
    let formdata = new FormData();
    formdata.append('demo_id', demo_id);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/join_demo_class`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response?.data?.status == false) {
          window?.open(`//${zoom_link}`);
        }
        else {
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // AM PM FUNCTION
  function tConvert(time: any) {
    time = time?.toString()?.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
      time = time?.slice(1);
      time[5] = +time[0] < 12 ? 'AM' : 'PM';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }

  return (
    <>
      <Header />
      <ToastContainer limit={1} />
      <section className={`${st.pageContent}`}>
        <div className={`container ${st.leftContent}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.titleBar} ${st.fixHeader}`}>
              <Row className={`align-items-center ${st.width100}`}>
                <Col className={`col-2`}>
                </Col>
                <Col className={`col-8 ${cx.headTitle}`}>
                  <h6>Calendar</h6>
                </Col>
                <Col className={`col-2 text-end`}>
                </Col>
              </Row>
            </div>
          </div>

          <div className={`${cx.spaceBox}`}></div>
          <CustomDatePicker getSelectedDay={selectedDay} labelFormat={"MMMM"} color={"#374e8c"} />
          <div className={` ${cx.contentBody}`}>

            {/*  demo Data */}
            <Row>
              {
                data?.length == 0 && getEnroll?.length == 0 ? (
                  <div className="d-flex justify-content-center">
                  </div>
                ) :
                  data?.map((item: any) => {
                    let localTime = new Date(`${item?.utc_select_date}`)?.toLocaleString("en-US", { timeZone: localData?.timezone });
                    let showTime = localTime.split(",");
                    let matchDate1 = localTime?.slice(0, 10)?.split("/");
                    let date1 = +matchDate1[0] < 10 ? `0${matchDate1[0]}` : matchDate1[0]
                    let date2 = +matchDate1[1] < 10 ? `0${matchDate1[1]}` : matchDate1[1]
                    let matchDate2 = date?.split('-')
                    if (Number(`${date1}${date2}${matchDate1[2]?.slice(0, 4)}`) == Number(`${matchDate2[1]}${matchDate2[2]}${matchDate2[0]}`)) {
                      return (
                        <Col lg={4}>
                          <div className={`${cx.classCard}`}>
                            <ul>
                              <button className="btn" style={{ width: '20%' }}>Demo</button>
                              <li>
                                <span><h5>{item?.teacher_name || "Not Available"}</h5></span>
                                <span>Time -{tConvert(showTime[1]) || "Not Available"}</span>
                              </li>
                              <li>
                                <span>{item?.subject_name ? "Subject" : "Course"} - {item?.subject_name || item?.courses_name}</span>
                                <span>{item?.class_name ? "Class" : "Category"} - {item?.class_name || item?.category_name}</span>
                              </li>
                              {/* <li>
                                <span>Class Url - </span>
                                <span><a href={`//${item?.class_url}`}>{item?.class_url.slice(0, 20) || "Not Available"}</a></span>
                              </li> */}
                              <li>
                                <span>Class Url - </span>

                                <button className={`${cx.linkBtn} btn`}
                                  onClick={() => {
                                    joinDemo(item?.id, item?.zoom_link || item?.class_url);
                                  }}>Join
                                  {/* {demoStatus == false ? <a target="_blank" href={`//${item?.zoom_link || item?.class_url}`}>Join</a> : "Join"} */}
                                </button>
                                {/* <spans
                                  onClick={() => {
                                    if (item?.class_start_status == 1 && item?.class_end_status == 0) {
                                      <a target="_blank" href={`${item?.class_url}`}></a>
                                    } else if (item?.class_start_status == 0) {
                                      notify("Teacher hasn't started the class yet")
                                    } else {
                                      notify("Class is already ended")
                                    }
                                  }}></span> */}
                              </li>
                            </ul>
                          </div>
                        </Col>
                      );
                    };
                  })}
            </Row>

            {/*  enroll Data */}
            <Row>
              {
                data?.length == 0 && getEnroll?.length == 0 ? (
                  <div className="d-flex justify-content-center">
                    {loaderStatus}
                  </div>
                ) :
                  getEnroll?.map((item: any) => {
                    let isoStartDateString = `${item?.select_date}T${item?.start_time}Z`;
                    let localTime = new Date(isoStartDateString)?.toLocaleString("en-US", { timeZone: localData?.timezone });
                    let showTime = localTime.split(",")
                    let matchDate1 = localTime?.slice(0, 10)?.split("/");
                    let date1 = +matchDate1[0] < 10 ? `0${matchDate1[0]}` : matchDate1[0]
                    let date2 = +matchDate1[1] < 10 ? `0${matchDate1[1]}` : matchDate1[1]
                    let matchDate2 = date?.split('-')
                    if (Number(`${date1}${date2}${matchDate1[2]?.slice(0, 4)}`) == Number(`${matchDate2[1]}${matchDate2[2]}${matchDate2[0]}`)) {
                      return (
                        <Col lg={4}>
                          <div className={`${cx.classCard}`}>
                            <ul>
                              <li>
                                <span><h5>{item?.teacher_name || "Not Available"}</h5></span>
                                <span>Time -{showTime[1] || "Not Available"}</span>
                              </li>
                              <li>
                                <span>{item?.subject_name ? "Subject" : "Course"} - {item?.subject_name || item?.courses_name}</span>
                                <span>{item?.class_name ? "Class" : "Category"} - {item?.class_name || item?.category_name}</span>
                              </li>
                              <li>
                                <span>Class Url - </span>
                                {/* <span className="btn" onClick={() => {
                                  joinUpcomming(item?.id)
                                }}></span>
                                {upcomingStatus === false ? <a target="_blank" href={`//${item?.zoom_link}`}>Join</a> : 'Join'} */}
                                <button className={`${cx.linkBtn} btn`}
                                  onClick={() => {
                                    joinUpcomming(item?.student_enroll_schedule_id, item?.zoom_link || item?.class_url);
                                  }}>Join
                                  {/* {upcomingStatus == false ? <a target="_blank" href={`//${item?.zoom_link || item?.class_url}`}>Join</a> : "Join"} */}
                                </button>
                                {/* {<a target="_blank" href={`//${item?.class_url}`} className="btn" onClick={() => {
                                  joinUpcomming(item?.id)
                                }}>
                                  Join
                                </a>} */}
                              </li>
                            </ul>
                          </div>
                        </Col>
                      );
                    }
                  })}
            </Row>
          </div>
          <div className={`${st.spaceBox}`}></div>
        </div>
        <Bottombar />
      </section >
      <Footer />
    </>
  );
}