import React from "react";
import m from "./Modal.module.scss";
import { Modal, Row, Col } from "react-bootstrap";
import ProfileAvatar from "../../../Custom_Components/ProfileAvatar/ProfileAvatar";

const StudentDetails = (props: any) => {
    let { showStudentDetails, handleStudentDetailsClose } = props;
    return (
        <>
            <Modal
                centered
                show={showStudentDetails}
                onHide={handleStudentDetailsClose}
                aria-labelledby="example-modal-sizes-title-lg"
                size="lg"
                className={`${m.modalCts}`}
            >
                <Modal.Body>
                    <div className={`${m.student_detailsPopup}`}>
                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <div className={`${m.imgbox}`}>
                                        {props?.upcomingData?.student_picture == "" ||props?.upcomingData?.student_picture == null ?
                                            <ProfileAvatar name={props?.upcomingData?.student_name} />
                                            : <img className={`${m.imgClass}`} src={props?.upcomingData?.student_picture} alt={props?.upcomingData?.student_name} />}
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Name:</label>
                                    <div className="form-control">{props?.upcomingData?.student_name || "NA"}</div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Curriculum:</label>
                                    <div className="form-control">{props?.upcomingData?.curriculum || "NA"}</div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3">
                                    <label>Query:</label>
                                    <div className="form-control">{props?.upcomingData?.query_no || 'NA'}</div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3">
                                    <label>Current Grade:</label>
                                    <div className="form-control">{props?.upcomingData?.class_name || "NA"}</div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3">
                                    <label>Time Zone:</label>
                                    <div className="form-control">{props?.upcomingData?.timezone || "NA"}</div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Country:</label>
                                    <div className="form-control">{props?.upcomingData?.country || "NA"}</div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3">
                                    <label>Preferred Timing:</label>
                                    <div className="form-control">{props?.upcomingData?.preferred_time || "NA"}</div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className={`${m.btnGroup}`}>
                        <button style={{ backgroundColor: "#375dbc", color: "#fff" }} className={`btn ${m.actionBtn}`} onClick={handleStudentDetailsClose}>Close</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default StudentDetails;
