import { initializeApp } from "firebase/app";
import {
    getAuth,
    GoogleAuthProvider,
    FacebookAuthProvider,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { getStorage } from "firebase/storage";

// Testing
// export const FirebaseConfig = {
//     apiKey: "AIzaSyCHPNzTLk-_zFaPpYVcYzniCZQAj49cUH0",
//     authDomain: "testproject-a9d31.firebaseapp.com",
//     projectId: "testproject-a9d31",
//     storageBucket: "testproject-a9d31.appspot.com",
//     messagingSenderId: "831552571606",
//     appId: "1:831552571606:web:87a92da21e825432bda177",
//     measurementId: "G-1JJ9VD7MK7"
// }

// Production
const FirebaseConfig = {
    apiKey: "AIzaSyAdIpTsDwTYbfS7c70xLVMJ4JpZPuVszVc",
    authDomain: "guru-at-home.firebaseapp.com",
    databaseURL: "https://guru-at-home-default-rtdb.firebaseio.com",
    projectId: "guru-at-home",
    storageBucket: "guru-at-home.appspot.com",
    messagingSenderId: "131183761935",
    appId: "1:131183761935:web:711ef52e6f4d419023a17a",
    measurementId: "G-BLLHLN722C"
};
// Initialize Firebase
const app = initializeApp(FirebaseConfig);
const auth = getAuth(app);
const messaging = getMessaging(app)
const provider = new GoogleAuthProvider();
const storage = getStorage();
const db = getFirestore(app)
const firestore = getFirestore(app);
const providerFacebook = new FacebookAuthProvider();
export { auth, provider, providerFacebook, messaging, app, storage, db, firestore };

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload: any) => {
            resolve(payload);
        });
    });

export const getDeviceToken = async () => {
    const token = await getToken(messaging, { vapidKey: "BEJzO2GVQHkpDT6q5VPqrqRrua0ubSZD6mfHqXg2JRBtw2bBSvQTs7uFi4Q30nHUzxHK1WkiQqYZc31rxmKSjUQ" }) // ----> Production
        // const token = await getToken(messaging, { vapidKey: "BJfRpUG_lFKY2pJmlukvKInuPC80QcmYwIAH-cvKniq7Wqw0yaFTRXtvNgDtC6bDH2HKI5fmw_hn56xq8kGJGsQ" }) //---> Test Vapid Key
        .then((currentToken: any) => {
            return currentToken
        })
        .catch((err: any) => {
            // Handle error
            console.log(err, "errorMessage")
        });
    return token
}