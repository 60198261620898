import React, { useState, useEffect } from "react";
import st from "../../../style.module.scss";
import cx from "./Signup.module.scss";
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useHistory } from "react-router-dom";
import { Col, Form } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg-img-new.svg";
import logo from "../../../images/logo.png";
import google from "../../../images/google.svg";
import facebook from "../../../images/facebook.svg";
// import firebase from "firebase";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { RotatingLines } from "react-loader-spinner";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, provider } from "../../../firebase";

// let provider = new firebase.auth.GoogleAuthProvider();
// provider.addScope("https://www.googleapis.com/auth/contacts.readonly");

export default function Teachersignup() {
  const navigate = useHistory();
  const [show, setShow] = useState(true);
  const [show2, setShow2] = useState(true);
  const [fcmToken, setfcmToken] = useState('');
  const [loadingSpinner, setLoadingSpinner] = useState<any>('Sign Up');

  // useEffect(() => {
  //   const msg: any = firebase.messaging();
  //   msg.requestPermission().then(() => {
  //     return msg.getToken();
  //   }).then((data: any) => {
  //     setfcmToken(data);
  //   })
  // })

  //----validation----//
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  //---react-toastify---//
  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);

  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify]);
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  //------------------------------------ SIGNUP API ------------------------------------//
  function addteachernew(postdata: any) {

    let formdata = new FormData();
    formdata.append('name', postdata.name);
    formdata.append('email', postdata.email);
    formdata.append('password', postdata.password);
    formdata.append('confirm_password', postdata.confirm_password);
    formdata.append('type', "1");

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/teacher-register`,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    setLoadingSpinner(
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />)
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: '/teacher/otp-verification',
              state: { detail: response.data.id, email: postdata.email, type: 1, component: "signup" }
            });
          }, 2000);
        }
        else {
          setLoadingSpinner('Sign Up')
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }

      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
        setTimenotify(error.response.data.message);
      });
  }

  //------------------------------------ SIGNUP API GOOGLE/FACEBOOK ------------------------------------//
  function loginstudentSSO(name: any, email: any, type: number) {
    let formdata = new FormData();
    formdata.append('name', name);
    formdata.append('email', email);
    formdata.append('social_login_type', type.toString());
    formdata.append('usertype', "2");
    formdata.append('deviceToken', fcmToken);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/social-signup`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };

    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          if (response.data.data.teacher_complete_profile_status == 0) {
            setTimeout(() => {
              navigate.push('/teacher/complete-profile', {
                userId: response.data.data.id,
                name: response.data.data.name
              });
            }, 3000);
            setTimenotify(response.data.message);
            setOnetimenotify(true)
          }
          else {
            localStorage.setItem("api_token", response.data.data.api_token);
            localStorage.setItem("userData", JSON.stringify(response.data.data));
            setTimeout(() => {
              navigate.push("/teacher/home");
            }, 3000);
            setTimenotify(response.data.message);
            setOnetimenotify(true);
          }
        }
        else {
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        notify(error?.response?.data?.message);
      });
  }

  // function signInWithGoogle(e: any) {
  //   firebase.auth()
  //     .signInWithPopup(provider)
  //     .then((result) => {
  //       /** @type {firebase.auth.OAuthCredential} */
  //       var credential: any = result.credential;
  //       var token = credential.accessToken;
  //       var user = result.user;
  //       loginstudentSSO(user?.displayName, user?.email, e)
  //     }).catch((error) => {
  //       var errorCode = error.code;
  //       var errorMessage = error.message;
  //       var email = error.email;
  //       var credential = error.credential;
  //     });
  // }

  const handleClick = () => {
    signInWithPopup(auth, provider)
      .then((result: any) => {
        const credential: any = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        loginstudentSSO(user?.displayName, user?.email, 2);
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage)
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };


  const responseFacebook = (response: any) => {
    if (response?.name) {
      loginstudentSSO(response?.name, response?.email, 1)
    }
  };

  // function lettersOnly(input: any) {
  //   let regex = /[^a-z ]/gi;
  //   input.value = input.value.replace(regex, "");
  // }

  return (
    <>
      <ToastContainer limit={1} />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <img src={bg} className={`${cx.bgShape}`} />
            <Col className="text-center"><img src={logo} className={`${cx.logo}`} />
            </Col>
          </div>

          <div className={`${cx.contentBody} mt-3`}>
            <Col lg={4} className={`m-auto`}>
              <h5 className={`${st.themeColor}`}>Hello!</h5>
              <p className={`${st.fontColor}`}>Create an account</p>
            </Col>
            <Col lg={4} className={'m-auto'}>
              <Form onSubmit={handleSubmit(addteachernew)} className={`${st.formFull}`}>
                <Form.Group className={`${st.formField}`} controlId="">
                  <Form.Control placeholder="Name" type="text"
                    {...register("name", {
                      required: true,
                      pattern: /^[a-zA-Z ]+$/,
                      min: 1,
                    })}
                  />
                  {errors?.name?.type === "pattern" && (
                    <p style={{ color: "red" }}>Numbers not allowed</p>
                  )}
                  {errors?.name?.type === "required" && (
                    <p style={{ color: "red" }}>
                      This field is required
                    </p>
                  )}
                </Form.Group>

                <Form.Group className={`${st.formField}`} controlId="">
                  <Form.Control type="email" placeholder="Email"
                    {...register("email", {
                      required: true,
                      pattern:
                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    })}
                  />
                  {errors?.email?.type === "pattern" && (
                    <p style={{ color: "red" }}>Invalid email address</p>
                  )}
                  {errors?.email?.type === "required" && (
                    <p style={{ color: "red" }}>
                      This field is required
                    </p>
                  )}
                </Form.Group>

                <Form.Group className={`${st.formField}`} controlId="">
                  <div className="position-relative">
                    <Form.Control type={show ? "password" : "text"} placeholder="Enter Password"
                      {...register("password", {
                        required: "This password is required",
                        minLength: {
                          value: 6,
                          message: "Please enter minimum 6 characters",
                        },
                      })}
                    />
                    {show ? (
                      <AiFillEyeInvisible
                        className={`${st.actionIcon}`}
                        onClick={() => {
                          setShow(false);
                        }}
                      />
                    ) : (
                      <AiFillEye
                        className={`${st.actionIcon}`}
                        onClick={() => {
                          setShow(true);
                        }}
                      />
                    )}
                  </div>

                  {errors?.password?.type === "minLength" && (
                    <p style={{ color: "red" }}>
                      Please enter minimum 6 characters
                    </p>
                  )}
                  {errors?.password?.type === "required" && (
                    <p style={{ color: "red" }}>This field is required</p>
                  )}
                </Form.Group>

                <Form.Group className={`${st.formField}`} controlId="">
                  <div className="position-relative">
                    <Form.Control type={show2 ? "password" : "text"} placeholder="Confirm Password"
                      {...register("confirm_password", {
                        required: "This password is required",
                        minLength: {
                          value: 6,
                          message: "Please enter minimum 6 characters",
                        },
                      })}
                    />
                    {show2 ? (
                      <AiFillEyeInvisible
                        className={`${st.actionIcon}`}
                        onClick={() => {
                          setShow2(false);
                        }}
                      />
                    ) : (
                      <AiFillEye
                        className={`${st.actionIcon}`}
                        onClick={() => {
                          setShow2(true);
                        }}
                      />
                    )}
                  </div>

                  {errors?.confirm_password?.type === "minLength" && (
                    <p style={{ color: "red" }}>
                      Please enter minimum 6 characters
                    </p>
                  )}
                  {errors?.confirm_password?.type === "required" && (
                    <p style={{ color: "red" }}>This field is required</p>
                  )}
                </Form.Group>

                <Form.Group className={`${st.formField}`}>
                  <Col lg={12}>
                    {/* <NavLink to="/teacher/otp-verification" className={`btn ${st.actionBtn}`}>
                      Sign Up
                    </NavLink> */}
                    <button type="submit" className={`btn ${st.actionBtn}`}>
                      {loadingSpinner}
                    </button>
                  </Col>
                </Form.Group>

                <Form.Group className={`${st.formField}`}>
                  <Col lg={12}>
                    <p className={`${st.themeColor} text-center mb-0 mt-4`}>Or Continue with</p>
                    <ul className={`${cx.socialSignup}`}>
                      <li>
                        <span onClick={handleClick}>
                          <img src={google}
                          />
                        </span>
                      </li>
                      <li>
                        <span>
                          <FacebookLogin
                            appId="380045221830282"
                            autoLoad={false}
                            fields="name,email,picture"
                            callback={responseFacebook}
                            render={(renderProps) => (
                              <img onClick={renderProps.onClick} src={facebook} />
                            )} />
                        </span>
                      </li>
                    </ul>
                    <p className={`${st.fontColor} text-center mt-3`}>Already have an account? <NavLink className={`${st.themeColor} text-decoration-none`} to="/teacher/login">Login</NavLink></p>
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </div>
        </div>
      </section>
    </>
  );
}