import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header/Header';
import Footer from '../../../components/Teacher/Footer/Footer';
import st from "../../../style.module.scss";
import cx from "./DetailList.module.scss";
import { Col, Container, Row } from 'react-bootstrap';
import UpcomingReschdule from '../../../components/Modal/Teacher/UpcomingReschdule';
import axios from 'axios';
import ApprovedSlot from '../../../components/Modal/Teacher/ApprovedSlot';
import CustomerSupport from '../../../components/Modal/Teacher/CustomerSupport';
import { useParams } from 'react-router';

const BasicDetailList = () => {
  useEffect(() => {
    particularClass();
  }, [])

  let localData = JSON.parse(localStorage.getItem("userData")!);
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [isApprovedSlottsModalOpen, setIsApprovedSlottsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [demoData, setDemoData] = useState([]);
  const [upcomingRes, setUpcomingRes] = useState([])
  const [student_apply_for_demo_id, setstudent_apply_for_demo_id] = useState('')
  const params: any = useParams();
  const handleRescheduleButtonClick = () => {
    setIsRescheduleModalOpen(true);
  };
  const handleApprovedSlotsButtonClick = () => {
    setIsApprovedSlottsModalOpen(true);
  };

  const [showCustomerSupport, setCustomerSupportShow] = useState(false);
  const handleCustomerSupportClose = () => setCustomerSupportShow(false);
  const handleCustomerSupportShow = () => setCustomerSupportShow(true);

  function particularClass() {
    let formdata = new FormData();
    formdata.append('teacher_id', localData?.id);
    formdata.append('student_id', params?.id?.split("&")[0]);
    formdata.append('type', params?.id?.split("&")[1]);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/student_teacher_classes`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response: any) {
        setData(response?.data?.data)
        setDemoData(response?.data?.demo)
      })
      .catch(function (error) {
        console.log(error, "error");
      });
  }

  return (
    <>
      <Header />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent}`}>

          <Container>
            <div className={`${cx.mainBox}`}>
              <div className={`${cx.headerTitle}`}>
                <h1>Slot Confirmation</h1>
              </div>
            </div>
            <Row>
              {demoData?.map((item: any) => {
                let utcTime = new Date(`${item?.utc_start_time || item?.utcdate || item?.select_date}`).toLocaleString("en-US", { timeZone: localData?.timezone });
                let dateNtime = utcTime?.split(',')
                return (
                  <Col lg={6}>
                    <div className={`${cx.classCard}`}>
                      <ul>
                        <li>
                          <span>
                            <h5>Student- <label className={`${st.themeColor}`}>{item?.student_name}</label></h5>
                          </span>
                          <span> Date- <label className={`${st.themeColor}`}>{dateNtime[0] ?? 'yy-mm-dd'}</label></span>
                        </li>
                        <li>
                          <span>
                            {item?.subject_name ? 'Subject' : 'Course'}- <label className={`${st.themeColor}`}>{item?.subject_name ?? item?.course_name}</label>
                          </span>
                          <span>Start Time- <label className={`${st.themeColor}`}>{dateNtime[1] || 'hh:mm'}</label></span>
                        </li>
                        <li>
                          <span>{item?.class_name ? 'Class' : 'Category'}- <label className={`${st.themeColor}`}>{item?.class_name ?? item?.category_name}</label></span>
                        </li>
                        <li>
                          <span>
                            Class Url-
                          </span>
                          <span>
                            <div className={`${cx.classinput_box}`} title={item?.zoom_link}>
                              <input
                                type="text"
                                value={item?.zoom_link}
                                readOnly
                                style={{ width: '100%', border: 'none', background: 'transparent', padding: 0, cursor: 'pointer' }}
                                onDoubleClick={(e: any) => e.target.select()}
                              />
                            </div>
                          </span>
                          {/* <span>
                            <div className={`${cx.classinput_box}`}>
                              {item?.zoom_link}
                            </div>
                          </span> */}
                        </li>
                      </ul>
                      <Row>
                        <Col sm={6}>
                          {item?.select_date == "yy-mm-dd" ||
                            item?.start_time == "hh-mm" ||
                            item?.select_date == "" ||
                            item?.start_time == null ?
                            <button disabled className="btn btn-primary mt-2">
                              Reschedule
                            </button>
                            : <button className="btn btn-primary mt-2" onClick={() => {
                              handleRescheduleButtonClick();
                              setUpcomingRes(item);
                            }}>
                              Reschedule
                            </button>}
                        </Col>
                        <Col sm={6}>
                          <button className="btn btn-primary mt-2" onClick={() => {
                            setstudent_apply_for_demo_id(item?.student_apply_for_demo_id)
                            handleCustomerSupportShow();
                          }}>Contact Support</button>
                        </Col>
                        <Col sm={12}>
                          <button className="btn btn-primary mt-2"
                            onClick={() => {
                              handleApprovedSlotsButtonClick();
                              setUpcomingRes(item);
                            }}>Approve</button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                )
              })}
            </Row>
            <Row>
              {data?.map((item: any) => {
                let utcTime = new Date(`${item?.utc_start_time || item?.utcdate || item?.select_date}`).toLocaleString("en-US", { timeZone: localData?.timezone });
                let dateNtime = utcTime?.split(',')
                return (
                  <Col lg={6}>
                    <div className={`${cx.classCard}`}>
                      <ul>
                        <li>
                          <span>
                            <h5>Student- <label className={`${st.themeColor}`}>{item?.student_name}</label></h5>
                          </span>
                          <span> Date- <label className={`${st.themeColor}`}>{dateNtime[0] ?? 'yy-mm-dd'}</label></span>
                        </li>
                        <li>
                          <span>
                            {item?.subject_name ? 'Subject' : 'Course'}- <label className={`${st.themeColor}`}>{item?.subject_name ?? item?.course_name}</label>
                          </span>
                          <span>Start Time- <label className={`${st.themeColor}`}>{dateNtime[1] || 'hh:mm'}</label></span>
                        </li>
                        <li>
                          <span>{item?.class_name ? 'Class' : 'Category'}- <label className={`${st.themeColor}`}>{item?.class_name ?? item?.category_name}</label></span>
                        </li>
                        <li>
                          <span>
                            Class Url-
                          </span>
                          <span>
                            <div className={`${cx.classinput_box}`} title={item?.zoom_link}>
                              <input
                                type="text"
                                value={item?.zoom_link}
                                readOnly
                                style={{ width: '100%', border: 'none', background: 'transparent', padding: 0, cursor: 'pointer' }}
                                onDoubleClick={(e: any) => e.target.select()}
                              />
                            </div>
                          </span>
                        </li>
                      </ul>
                      <Row>
                        <Col sm={6}>
                          {item?.select_date == "yy-mm-dd" ||
                            item?.start_time == "hh-mm" ||
                            item?.select_date == "" ||
                            item?.start_time == null ?
                            <button disabled className="btn btn-primary mt-2">
                              Reschedule
                            </button>
                            : <button className="btn btn-primary mt-2" onClick={() => {
                              handleRescheduleButtonClick();
                              setUpcomingRes(item);
                            }}>
                              Reschedule
                            </button>}
                        </Col>
                        <Col sm={6}>
                          <button className="btn btn-primary mt-2" onClick={() => {
                            setstudent_apply_for_demo_id(item?.enroll_id)
                            handleCustomerSupportShow();
                          }}>Contact Support</button>
                        </Col>
                        <Col sm={12}>
                          <button className="btn btn-primary mt-2"
                            onClick={() => {
                              handleApprovedSlotsButtonClick();
                              setUpcomingRes(item);
                            }}>Approve</button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                )
              })}
            </Row>
            {isRescheduleModalOpen && (
              <UpcomingReschdule
                props={upcomingRes}
                cx={cx}
                show={isRescheduleModalOpen}
                handleClose={() => setIsRescheduleModalOpen(false)}
                type={params?.id?.split("&")[1]}
              />
            )}
            {isApprovedSlottsModalOpen && (
              <ApprovedSlot
                props={upcomingRes}
                cx={cx}
                particularClass={particularClass}
                show1={isApprovedSlottsModalOpen}
                handleClose1={() => setIsApprovedSlottsModalOpen(false)}
                type={params?.id?.split("&")[1]}
              />
            )}
          </Container>
        </div>
      </section >
      <Footer />

      <CustomerSupport id={student_apply_for_demo_id}
        show={showCustomerSupport}
        handleCustomerSupportClose={handleCustomerSupportClose}
      />
    </ >
  )
}

export default BasicDetailList