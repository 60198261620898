import React, { useState, useEffect } from "react";
import st from "../../../style.module.scss";
import cx from "./Otp.module.scss";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import OtpInput from "react18-input-otp";
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import bg from "../../../images/bg-img-new.svg";
import logo from "../../../images/logo.png";
import { RotatingLines } from "react-loader-spinner";

export default function Teacherotp() {

  //---react-toastify-----//
  const notify = (message: String) =>
    toast(message, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  const [timenotify, setTimenotify] = useState("");
  const [onetimenotify, setOnetimenotify] = useState(false);
  useEffect(() => {
    if (onetimenotify) {
      notify(timenotify);
      setTimeout(() => {
        setOnetimenotify(false);
      }, 3000);
    }
  }, [onetimenotify])

  const [otp, setOtp] = useState("");
  const [type] = useState<any>(1);
  const [loadingSpinner, setLoadingSpinner] = useState<any>('Verify');
  const handleChange = (enteredOtp: any) => {
    setOtp(enteredOtp);
  };

  const location: any = useLocation()
  let id = location?.state?.detail
  const navigate = useHistory();
  const [boolean, setBoolean] = useState(location?.state?.boolean)
  const [disablebtn, setDisableBtn] = useState(false);
  const [resentOtp, setResentOtp] = useState(false);

  //------------------------Otp match API-----------------------//
  function otpMatch(e: any) {
    e.preventDefault();

    let formdata = new FormData();
    formdata.append('otp', otp);
    formdata.append('userId', id);
    formdata.append('type', "1")

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/teacher-otpMatch`,
      data: formdata,
    };
    setLoadingSpinner(
      <RotatingLines
        strokeColor="white"
        strokeWidth="5"
        animationDuration="0.75"
        width="25"
        visible={true}
      />)
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.replace({
              pathname: "/teacher/complete-profile",
              state: { userId: response.data.data.userId, name: response.data.data.name }
            });
          }, 1000);
        } else {
          if (type == 2)
            setTimeout(() => {
              navigate.push({
                pathname: '/teacher/password-forgot',
                state: { userId: response.data.data.userId, name: response.data.data.name }
                // userId: { detail: response.data.userId },
                // email: { detail: response.data.email }
              });
            }, 3000);
          setTimenotify(response.data.message);
          setOnetimenotify(true)
          setLoadingSpinner('Verify')
        }
      })
      .catch(function (error) {
        setTimenotify(error.response.data.message);
      });
  }

  //---------------------- Forgot password API-------------------------// 
  function enterOtp(e: any) {
    setDisableBtn(true);
    e.preventDefault();

    let formdata = new FormData();
    formdata.append('otp', otp);
    formdata.append('userId', id);
    formdata.append('type', "2");

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/teacher-VerfyOtp`,
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
          setTimeout(() => {
            navigate.push({
              pathname: "/teacher/password-forgot",
              state: { detail: response.data.data.userId }
            })
          }, 1000);
        } else {
          setDisableBtn(false);
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        setDisableBtn(false);
        setTimenotify(error.response.data.message);
      });
  }

  // ---------------------- Otp Resend API --------------------------//
  function resendOtp(e: any) {
    setResentOtp(true);
    e.preventDefault();
    let formdata = new FormData();
    formdata.append('email', location?.state?.email);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/teacher-resendOtp`,
      headers: {
        Authorization: "Bearer" + localStorage.getItem("api_token"),
      },
      data: formdata,
    };
    axios(config)
      .then(function (response) {
        setTimeout(() => {
          setResentOtp(false);
        }, 3000);
        if (response.data.statusCode == 200) {
          setTimenotify(response.data.message);
          setOnetimenotify(true);
        } else {
          setTimeout(() => {
            setResentOtp(false);
          }, 3000);
          setTimenotify(response.data.message);
          setOnetimenotify(true)
        }
      })
      .catch(function (error) {
        setTimeout(() => {
          setResentOtp(false);
        }, 3000);
        setTimenotify(error.response.data.message);
      });
  }

  // ----------------------- Check forgot password otp API -------------------------//
  function forgotpassword(e: any) {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append('userId', location?.state?.detail);
    formdata.append('otp', otp);

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_BASEURL}/checkForgetPasswordOtp`,

      data: formdata,
    };
    axios(config)
      .then(function (response) {
        if (response.data.statusCode == 200) {
          setTimeout(response.data.message);
          setBoolean(true)
          setTimeout(() => {
            navigate.push("/teacher/change-password");
          }, 1000);
        } else {
          setBoolean(true);
        }
      })
      .catch(function (error) {
        setTimenotify(error.response.data.msg);
      });
  }

  return (
    <>
      <ToastContainer limit={1} />
      <section className={`${st.pageContent}`}>
        <div className={`${st.leftContent} ${st.marginTopp}`}>
          <div className={`${cx.headerTitle}`}>
            <div className={`${cx.sticky}`}>
            </div>
            <img src={bg} className={`${cx.bgShape}`} />
            <Col className="text-center"><img src={logo} className={`${cx.logo}`} />
            </Col>
          </div>

          <div className={`${cx.contentBody} mt-3`}>
            <Col lg={4} className={'m-auto'}>
              <h5 className={`${st.themeColor}`}>Verify</h5>
              <p className={`${st.fontColor}`}>We have sent an OTP on your email- {location?.state?.email}</p>
            </Col>
            <Col lg={4} className={'m-auto'}>
              <Form className={`${st.formFull}`}>
                <Form.Group className={`${st.formField}`} controlId="">
                  <div className={`${cx.otpBox}`}>
                    <OtpInput
                      value={otp}
                      isInputNum={true}
                      className="form-control"
                      onChange={handleChange}
                      numInputs={4}
                      separator={<span></span>}
                    />
                  </div>
                  <p className={`${st.fontColor} text-left mt-4 mb-5`} style={{ fontSize: '15px' }}>Didn't receive the OTP?
                    {/* <NavLink className={`${st.themeColor} text-decoration-none`} onClick={resendOtp} to="#"> Send again</NavLink> */}
                    {resentOtp ? <button
                      disabled
                      onClick={resendOtp}
                      style={{
                        background: 'none',
                        border: 'none',
                        padding: '0',
                        color: 'grey',
                        marginLeft: "5px",
                        textDecoration: 'none',
                        cursor: 'pointer'
                      }}
                    >
                      Send again
                    </button> : <button
                      onClick={resendOtp}
                      style={{
                        background: 'none',
                        border: 'none',
                        padding: '0',
                        color: '#375dbc',
                        marginLeft: "5px",
                        textDecoration: 'none',
                        cursor: 'pointer'
                      }}
                    >
                      Send again
                    </button>}
                  </p>
                </Form.Group>

                <Form.Group className={`${st.formField}`}>
                  <Col lg={12}>
                    {disablebtn ? <button disabled className={`btn ${st.actionBtn}`}
                      onClick={(e) => {
                        location?.state?.type == "1" ? otpMatch(e) : boolean == true ? forgotpassword(e) : enterOtp(e)
                      }}>
                      {loadingSpinner}
                    </button> : <NavLink to="#" className={`btn ${st.actionBtn}`}
                      onClick={(e) => {
                        location?.state?.type == "1" ? otpMatch(e) : boolean == true ? forgotpassword(e) : enterOtp(e)
                      }}>
                      {loadingSpinner}
                    </NavLink>}
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </div>
        </div>
      </section>
    </>
  );
}